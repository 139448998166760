import { useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useWatch, useFieldArray, useFormState } from 'react-hook-form';

import cache from '../../../../../apollo/cache';
import client from '../../../../../apollo/client';
import AutocompleteSelect from '../../../../../components/selects/AutoCompleteSelect';
import Table from '../../../../../components/table/Table';
import usePrevious from '../../../../../custom-hooks/usePrevious';
import { GET_PRODUCTS } from '../../../../../gql/catalogs';
import { CURRENCY_FORMATTER } from '../../../../../lib/currency';
import { REQUIRED_FIELD, POSITIVE_NUMBER } from '../../../../../lib/form';
import { CURRENCIES_SALES_TAX_MAPPER } from '../../../../../lib/taxes';
import ItemQuantityCell from './ItemQuantityCell';

function NewOrderItems({ register, control }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [getProductsFilter, setGetProductsFilter] = useState({
    sellerId: '',
    globalFilter: '',
    hasComponents: false,
  });

  const { errors } = useFormState({ control });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'items',
    rules: { required: REQUIRED_FIELD },
  });
  const watchWarehouseId = useWatch({
    name: 'warehouseId',
    control,
  });
  const watchOrderCurrency = useWatch({
    name: 'orderCurrency',
    control,
  });
  const watchSellerId = useWatch({
    name: 'sellerId',
    control,
  });

  const prevFields = usePrevious(fields);

  useEffect(() => {
    replace([]);
  }, [watchSellerId, watchWarehouseId]);

  useEffect(() => {
    setGetProductsFilter((prevFilters) => ({
      ...prevFilters,
      sellerId: watchSellerId,
    }));
  }, [watchSellerId]);

  const addItem = () => {
    if (!fields.some((item) => item.value === selectedItem)) {
      const cacheObject = cache.identify({
        __typename: 'Product',
        id: selectedItem,
      });
      const product = client.readFragment({
        id: cacheObject,
        fragment: gql`
          fragment ProductObject on Product {
            id
            sku
            name
            value
            label
            storageType
            measures {
              width {
                value
                unit
              }
              height {
                value
                unit
              }
              length {
                value
                unit
              }
              weight {
                value
                unit
              }
            }
          }
        `,
      });
      append({
        ...product,
        quantity: 1,
        unitPrice: 1,
        storageType: product.storageType.value,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: <Typography>Sku</Typography>,
        accessor: 'sku',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Nombre</Typography>,
        accessor: 'name',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>Precio unitario</Typography>
            <Box sx={{ display: 'grid' }}>
              <Typography variant="caption">
                *Los precios deben tener incluido el{' '}
                {` ${CURRENCIES_SALES_TAX_MAPPER[watchOrderCurrency]}`}
              </Typography>
            </Box>
          </Box>
        ),
        id: 'unitPrice',
        accessor: 'value',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          const currentItemIndex = useMemo(
            () => fields.findIndex((item) => item.value === value),
            [fields, value],
          );
          const { onChange, onBlur, name, ref } = register(
            `items[${currentItemIndex}].unitPrice`,
            {
              required: REQUIRED_FIELD,
              min: {
                value: 1,
                message: POSITIVE_NUMBER,
              },
            },
          );
          // TODO: refactor FormTextField to handle custom errors and helper text
          return (
            <TextField
              sx={{ width: 250 }}
              key={`items[${currentItemIndex}].unitPrice`}
              size="small"
              type="number"
              error={Boolean(
                errors.items && errors.items[currentItemIndex]?.unitPrice,
              )}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              ref={ref}
              helperText={
                errors.items &&
                errors.items[currentItemIndex]?.unitPrice?.message
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {watchOrderCurrency}
                  </InputAdornment>
                ),
              }}
            />
          );
        },
      },
      {
        Header: <Typography>Cantidad</Typography>,
        accessor: 'value',
        id: 'quantity',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <ItemQuantityCell
            control={control}
            productId={value}
            fields={fields}
            prevFields={prevFields}
            register={register}
          />
        ),
      },
      {
        Header: <Typography>Monto total</Typography>,
        id: 'totalValue',
        accessor: 'value',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => {
          const watchItems = useWatch({
            name: 'items',
            control,
          });
          const currentItems = watchItems ?? [];
          const currentItemIndex = useMemo(
            () => currentItems.findIndex((item) => item.value === value),
            [currentItems, value],
          );
          const currentItem = currentItems[currentItemIndex];
          if (!currentItem) return <div />;
          return (
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography variant="caption">{watchOrderCurrency}</Typography>
              <Typography>
                {CURRENCY_FORMATTER[watchOrderCurrency].format(
                  currentItem.unitPrice * currentItem.quantity,
                )}
              </Typography>
            </Box>
          );
        },
      },
      {
        Header: '',
        id: 'delete',
        accessor: 'value',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => {
          const currentItemIndex = useMemo(
            () => fields.findIndex((item) => item.value === value),
            [fields, value],
          );
          return (
            <Box
              key={`delete-item${value}-box`}
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            >
              <IconButton
                onClick={() => {
                  remove(currentItemIndex);
                }}
              >
                <Tooltip title="Eliminar producto de la orden">
                  <ClearIcon />
                </Tooltip>
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [fields, control, watchOrderCurrency],
  );

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Typography variant="subtitle1">3. Seleccionar productos</Typography>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
        <AutocompleteSelect
          query={GET_PRODUCTS}
          label="Producto"
          filters={getProductsFilter}
          setFilters={setGetProductsFilter}
          setSelectedValue={setSelectedItem}
          size="small"
          disabled={!watchWarehouseId}
          validateFilters={(_filters) => Boolean(_filters.sellerId)}
          inputError={
            errors?.items ? { message: errors?.items?.root?.message } : false
          }
        />
        <Button
          size="medium"
          variant="contained"
          sx={{
            backgroundColor: '#01B0BB',
            borderRadius: 1,
            width: 250,
          }}
          startIcon={<AddOutlinedIcon />}
          disabled={!selectedItem}
          onClick={addItem}
        >
          Agregar producto
        </Button>
      </Box>

      {Boolean(fields?.length) && (
        <Box>
          <Typography sx={{ mb: 1 }}>Productos</Typography>
          <Table
            data={fields}
            columns={columns}
            pagination={{ hasPagination: false, customPageSize: fields.length }}
            hasSearchBox={false}
            paperBoxProps={{
              elevation: 0,
              sx: {
                borderRadius: '4px',
                border: '1px solid #EEEDED',
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
}
NewOrderItems.propTypes = {
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
};
export default NewOrderItems;
