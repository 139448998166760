import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Table from 'components/table/Table';
import { formatCurrencyValue } from 'lib/currency';

function OrderItems({ items, currency }) {
  const columns = useMemo(
    () => [
      {
        Header: <Typography>Sku</Typography>,
        accessor: 'sku',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Cantidad</Typography>,
        accessor: 'quantity',
        align: 'right',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Precio unitario</Typography>,
        accessor: 'unitPrice',
        align: 'right',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => (
          <Typography>
            {formatCurrencyValue({
              currency,
              value,
            })}
          </Typography>
        ),
      },
    ],
    [currency],
  );
  return (
    <Box sx={{ margin: 1 }}>
      <Table
        data={items}
        columns={columns}
        pagination={false}
        sxPaper={{ boxShadow: 'none' }}
        hasSearchBox={false}
      />
    </Box>
  );
}
OrderItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      unitPrice: PropTypes.number,
      quantity: PropTypes.number,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
};
export default OrderItems;
