import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

function ExpandedHeader({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IconButton aria-label="expand row" {...getToggleAllRowsExpandedProps()}>
      {isAllRowsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
}
ExpandedHeader.propTypes = {
  getToggleAllRowsExpandedProps: PropTypes.func.isRequired,
  isAllRowsExpanded: PropTypes.bool.isRequired,
};

export default ExpandedHeader;
