import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import ChipNumber from '../../components/chips/ChipNumber';
import Table from '../../components/table/Table';

function OrderItems({ items }) {
  const columns = useMemo(
    () => [
      {
        Header: <Typography>Sku</Typography>,
        accessor: 'sku',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Descripción</Typography>,
        accessor: 'description',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Cantidad</Typography>,
        accessor: 'quantity',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <ChipNumber label={value} />,
      },
    ],
    [],
  );
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Productos
      </Typography>
      <Table
        data={items}
        columns={columns}
        pagination={false}
        sxPaper={{ boxShadow: 'none' }}
        hasSearchBox={false}
      />
    </Box>
  );
}

OrderItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      description: PropTypes.string,
      quantity: PropTypes.number,
    }),
  ).isRequired,
};

export default OrderItems;
