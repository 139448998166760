import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

function ExpandedCell({ row }) {
  // react table can add canExpand property to rows if rows have subRows.
  return (
    <IconButton
      id="tableSubRow"
      aria-label="expand row"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...row.getToggleRowExpandedProps()}
    >
      {row.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
}

ExpandedCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
};

export default ExpandedCell;
