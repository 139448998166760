import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import RadioButtonsGroup from '../../../../../../components/radio-buttons/RadioButtonsGroup';
import FormTextField from '../../../../../../components/text-fields/FormTextField';
import { CURRENCY_FORMATTER } from '../../../../../../lib/currency';
import { REQUIRED_FIELD } from '../../../../../../lib/form';

const CUSTOM_PADDING = '5px';
const CUSTOM_MARGIN = '3px';

function ManualShippingValue({ shippingRate, control, register }) {
  const options = useMemo(
    () => [
      {
        value: 'defaultRate',
        label: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography pb={CUSTOM_PADDING}>
              {CURRENCY_FORMATTER[shippingRate.currency].format(
                shippingRate.price,
              )}
            </Typography>
            <Divider flexItem />
            <Typography variant="caption" mt={CUSTOM_MARGIN}>
              Tarifa calculada
            </Typography>
          </Box>
        ),
      },
      {
        value: 'manualRate',
        label: (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormTextField
              fieldName="manualShippingValue"
              control={control}
              variant="standard"
              register={() =>
                register('manualShippingValue', { required: REQUIRED_FIELD })
              }
              helperText="Tarifa personalizada"
              placeholder="Ingresa la tarifa"
              label="Ingresa la tarifa"
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
            />
          </Box>
        ),
      },
    ],
    [shippingRate],
  );
  return (
    <RadioButtonsGroup
      label={
        shippingRate.shippingMethod
          ? `Selecciona la tarifa de envío de ${shippingRate.serviceName}`
          : 'Selecciona la tarifa de envío'
      }
      options={options}
      control={control}
      fieldName="shippingRateType"
    />
  );
}
ManualShippingValue.propTypes = {
  shippingRate: PropTypes.shape({
    price: PropTypes.string,
    serviceName: PropTypes.string,
    shippingMethod: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
};
ManualShippingValue.defaultProps = {};
export default ManualShippingValue;
