import { gql } from '@apollo/client';

const CREATE_REPLENISHMENT = gql`
  mutation Mutation($input: CreateReplenishmentInput!) {
    createReplenishment(input: $input) {
      reference
    }
  }
`;

const GET_REPLENISHMENTS = gql`
  query getReplenishments(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: ReplenishmentFilters
  ) {
    replenishments(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        reference
        status
        comment
        createdAt
      }
      total
      pageCount
    }
  }
`;

const GET_REPLENISHMENT = gql`
  query replenishment($id: ID!) {
    replenishment(id: $id) {
      id
      reference
      seller {
        id
        name
      }
      warehouse {
        id
        name
      }
      status
      comment
      items {
        sku
        quantityReceived
        quantityDelta
        quantity
        unitCost
        costCurrency
        lotNumber
        expirationDate
      }
      createdAt
    }
  }
`;
export { GET_REPLENISHMENTS, GET_REPLENISHMENT, CREATE_REPLENISHMENT };
