import { useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import AppBar from './AppBar';
import SideBar from './SideBar';
import WrapperView from './WrapperView';

const openedMixin = (theme) => ({
  width: theme.drawer.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

function Layout({ children }) {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  });

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <SideBar
        variant="permanent"
        open={open}
        handleDrawerClose={handleDrawerClose}
        openedMixin={openedMixin}
        closedMixin={closedMixin}
      />
      <WrapperView>{children}</WrapperView>
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
