import Skeleton from '@mui/material/Skeleton';

import FilterBox from '../containers/FilterBox';

function SkeletonListData() {
  return (
    <>
      <FilterBox>
        <Skeleton
          variant="rectangular"
          sx={{ borderRadius: 5 }}
          width="100%"
          height={60}
        />
      </FilterBox>
      <Skeleton
        variant="rectangular"
        sx={{ borderRadius: 5 }}
        width="100%"
        height="50vh"
      />
    </>
  );
}

export default SkeletonListData;
