import { makeVar } from '@apollo/client';
import { subMonths } from 'date-fns';

const globalLoaderVar = makeVar(false);
const isLoggedInVar = makeVar(false);
const isAuthorizedVar = makeVar(false);
const orderFilterVar = makeVar({
  warehouseId: [],
  sellerId: [],
  sinceDate: subMonths(new Date(), 1).toString(),
  toDate: new Date().toString(),
});
const inventoryFilterVar = makeVar({
  warehouseId: '',
  sellerId: '',
});
const productFilterVar = makeVar({
  sellerId: '',
});
const replenishmentFilterVar = makeVar({
  sellerId: '',
  warehouseId: '',
});
const seenTourListInventoryVar = makeVar(
  localStorage.getItem('seenTourListProducts') === 'true',
);
const seenTourListProductsVar = makeVar(
  localStorage.getItem('seenTourListProducts') === 'true',
);
const seenTourListOrdersVar = makeVar(
  localStorage.getItem('seenTourListOrders') === 'true',
);

export {
  globalLoaderVar,
  isLoggedInVar,
  isAuthorizedVar,
  orderFilterVar,
  inventoryFilterVar,
  productFilterVar,
  replenishmentFilterVar,
  seenTourListProductsVar,
  seenTourListOrdersVar,
  seenTourListInventoryVar,
};
