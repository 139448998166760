import { useCallback, useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import usePaginatedQuery from '../../custom-hooks/usePaginatedQuery';
import onScroll from '../../helpers/events/onScroll';

const LOADING_TEXT = 'Cargando...';
const ERROR_TEXT = 'Hubo un error para cargar las opciones';
const EMPTY_DATA_TEXT = 'No se encontraron opciones disponibles';

function AutoCompleteSelect({
  query,
  label,
  setSelectedValue,
  filters,
  setFilters,
  size,
  validateFilters,
  disabled,
  inputError,
}) {
  const { data, loading, error, onLoadMore } = usePaginatedQuery({
    query,
    filters,
    validateFilters,
  });

  const inputValueInOptions = useCallback(
    (inputValue) =>
      data && data.map((option) => option.label).includes(inputValue),
    [data],
  );
  const getOptionLabel = useCallback(
    (option) => {
      const item =
        data && data.find((currentOption) => currentOption.value === option);
      return item ? `${item.label}` : '';
    },
    [data],
  );
  const options = useMemo(
    () => (data ? data.map((option) => option.value) : []),
    [data],
  );

  return (
    <Autocomplete
      id="autocomplete-select"
      size={size}
      fullWidth
      sx={{ flexGrow: 1 }}
      disabled={disabled || !validateFilters(filters)}
      onChange={(_event, newValue) => {
        setSelectedValue(newValue);
      }}
      noOptionsText={error ? ERROR_TEXT : EMPTY_DATA_TEXT}
      isOptionEqualToValue={(option, optionValue) => option === optionValue}
      getOptionLabel={getOptionLabel}
      options={options}
      filterOptions={() => options}
      onInputChange={(_event, newInputValue) => {
        if (!inputValueInOptions(newInputValue)) {
          setFilters((prev) => ({ ...prev, globalFilter: newInputValue }));
        }
      }}
      loadingText={LOADING_TEXT}
      loading={loading}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          error={Boolean(inputError)}
          helperText={inputError?.message}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="secondary" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, index) => {
        const key = `listItem-${index}-${option}`;
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={key}>
            {getOptionLabel(option)}
          </li>
        );
      }}
      ListboxProps={{
        role: 'list-box',
        sx: { maxHeight: '300px' },
        onScroll: (event) => {
          onScroll(event, onLoadMore);
        },
      }}
    />
  );
}
AutoCompleteSelect.propTypes = {
  label: PropTypes.string.isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  setFilters: PropTypes.func.isRequired,
  size: PropTypes.string,
  validateFilters: PropTypes.func, // fetch data if validateFilters returns true
  disabled: PropTypes.bool,
  inputError: PropTypes.oneOfType([
    PropTypes.shape({ message: PropTypes.string }),
    PropTypes.bool,
  ]),
  query: PropTypes.shape({ gql: PropTypes.string }).isRequired,
};
AutoCompleteSelect.defaultProps = {
  filters: {},
  size: 'small',
  validateFilters: () => true,
  disabled: false,
  inputError: false,
};

export default AutoCompleteSelect;
