const ERRORS = {
  UNKNOW_ERROR_MESSAGE: 'Unknown error',
  AUTHORIZATION_ERROR_MESSAGE: 'Not authorized',
  AUTHENTICATION_ERROR_MESSAGE: 'Unauthenticated',
  NOT_ENOUGH_STOCK_TO_FULFILL_THE_ORDER_ERROR_MESSAGE:
    'Not enough stock to fulfill the order',
  INVALID_PRODUCTS_REPLENISHMENT_MESSAGE: 'Some products are not registered',
  DUPLICATED_PRODUCTS_REPLENISHMENT_MESSAGE: 'Some SKUs are duplicated',
};

const LABEL_ERRORS = {
  UNKNOW_ERROR_MESSAGE: 'Error desconocido',
  AUTHORIZATION_ERROR_MESSAGE:
    'No tienes los permisos para acceder a esta función',
  AUTHENTICATION_ERROR_MESSAGE: 'Error de autenticación: inicia sesión primero',
  NOT_ENOUGH_STOCK_TO_FULFILL_THE_ORDER_ERROR_MESSAGE:
    'No hay inventario suficiente para crear la orden',
  USER_INPUT_ORDER_MESSAGE:
    'No pudimos crear la orden, revisa si los datos ingresados están correctos',
  UNKNOW_ERROR_ORDER_MESSAGE:
    'No pudimos crear la orden, ocurrió un error imprevisto',
  INVALID_PRODUCTS_REPLENISHMENT_MESSAGE:
    'Hay SKUs ingresados que no están registrados en nuestro sistema',
  DUPLICATED_PRODUCTS_REPLENISHMENT_MESSAGE: 'Hay SKUs duplicados',
  USER_INPUT_REPLENISHMENT_MESSAGE:
    'No pudimos crear el abastecimiento, revisa si los datos ingresados están correctos',
  UNKNOW_ERROR_REPLENISHMENT_MESSAGE:
    'No pudimos crear el abastecimiento, ocurrió un error imprevisto',
  USER_INPUT_MESSAGE:
    'No pudimos completar la solicitud, revisa si los datos ingresados están correctos.',
};

const STATUS_CODES = {
  BAD_REQUEST: 400,
};

export { ERRORS, LABEL_ERRORS, STATUS_CODES };
