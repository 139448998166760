import { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  ERRORS,
  LABEL_ERRORS,
  STATUS_CODES,
} from '../../../../apollo/serverErrors';
import AlertDialog from '../../../../components/dialogs/AlertDialog';
import PageTitle from '../../../../components/layout/PageTitle';
import FormTextField from '../../../../components/text-fields/FormTextField';
import { CREATE_ORDER } from '../../../../gql/orders';
import { CURRENCIES } from '../../../../lib/currency';
import { REQUIRED_FIELD } from '../../../../lib/form';
import ORDER_BUSINESS_TYPES from '../../../../lib/orderBusinessTypes';
import { CURRENCIES_SALES_TAX_MAPPER } from '../../../../lib/taxes';
import NewOrderSellerWarehouse from '../../../forms/SellerWarehouseInputForm';
import NewOrderCheckout from './components/checkout/NewOrderCheckout';
import NewOrderAttachments from './components/NewOrderAttachments';
import NewOrderCustomer from './components/NewOrderCustomer';
import NewOrderItems from './components/NewOrderItems';
import translateOrder from './lib/translateOrder';

function NewOrderForm() {
  const { register, control, handleSubmit, setValue, resetField } = useForm({
    defaultValues: {
      sellerId: '',
      warehouseId: '',
      orderCurrency: CURRENCIES.CLP,
      businessType: ORDER_BUSINESS_TYPES.B2C,
      checkoutRate: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      shippingAddressLine1: '',
      shippingAddressLine2: '',
      comment: '',
      orderNumber: '',
      manualShippingValue: 0,
    },
  });
  const watchCurrency = useWatch({
    name: 'orderCurrency',
    control,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const [dialogContent, setDialogContent] = useState('');
  const [mutationErrorMessage, setMutationErrorMessage] = useState();

  const [createOrder, { loading, error, reset: resetMutation }] = useMutation(
    CREATE_ORDER,
    {
      onCompleted: (data) => {
        const {
          createOrder: { sourceOrderId },
        } = data;
        resetMutation();
        setOpenDialog(true);
        setDialogContent(
          `Revisa que la orden con número: ${sourceOrderId} se haya creado correctamente. Puedes verlo en la vista de órdenes.`,
        );
      },
    },
  );

  const onSubmit = (data) => {
    const order = translateOrder(data);
    createOrder({ variables: { input: order } });
  };

  useEffect(() => {
    if (error) {
      switch (true) {
        case error.message ===
          ERRORS.NOT_ENOUGH_STOCK_TO_FULFILL_THE_ORDER_ERROR_MESSAGE:
          setMutationErrorMessage(
            LABEL_ERRORS.NOT_ENOUGH_STOCK_TO_FULFILL_THE_ORDER_ERROR_MESSAGE,
          );
          break;
        case error.networkError?.statusCode === STATUS_CODES.BAD_REQUEST:
          setMutationErrorMessage(LABEL_ERRORS.USER_INPUT_ORDER_MESSAGE);
          break;
        default:
          setMutationErrorMessage(LABEL_ERRORS.UNKNOW_ERROR_ORDER_MESSAGE);
      }
    }
  }, [error]);

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minWidth: 600,
        }}
      >
        <PageTitle>Crear una nueva orden</PageTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              1. Seleccionar moneda y tipo
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <FormTextField
                fieldName="orderCurrency"
                label="Moneda"
                control={control}
                register={() =>
                  register('orderCurrency', { required: REQUIRED_FIELD })
                }
                select={{
                  select: true,
                  options: Object.keys(CURRENCIES).map((currency) => ({
                    value: currency,
                    label: currency,
                  })),
                }}
              />
              <FormTextField
                fieldName="businessType"
                label="Tipo"
                control={control}
                register={() =>
                  register('businessType', { required: REQUIRED_FIELD })
                }
                select={{
                  select: true,
                  options: Object.keys(ORDER_BUSINESS_TYPES).map(
                    (businessType) => ({
                      value: businessType,
                      label: businessType,
                    }),
                  ),
                }}
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <PriorityHighOutlinedIcon color="warning" />
              <Typography variant="caption">
                Todos los precios de la orden tienen incluido el
                {` ${CURRENCIES_SALES_TAX_MAPPER[watchCurrency]}`}
              </Typography>
            </Box>
          </Box>
          <Divider flexItem />
          <NewOrderSellerWarehouse
            title="2. Vendedor y tienda"
            register={register}
            resetField={resetField}
            control={control}
            setValue={setValue}
          />
          <Divider flexItem />
          <NewOrderItems register={register} control={control} />
          <Divider flexItem />
          <NewOrderCustomer register={register} control={control} />
          <NewOrderCheckout
            register={register}
            control={control}
            setValue={setValue}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              6. ID de referencia (opcional)
            </Typography>
            <FormTextField
              fieldName="orderNumber"
              control={control}
              label="ID de referencia"
              sx={{ width: '100%' }}
              register={() => register('orderNumber')}
            />
          </Box>
          <Divider flexItem />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              7. Comentario (opcional)
            </Typography>
            <FormTextField
              fieldName="comment"
              control={control}
              label="Comentario"
              sx={{ width: '100%' }}
              register={() => register('comment')}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              8. Adjuntar archivos de referencia (opcional)
            </Typography>
            <NewOrderAttachments
              control={control}
              register={register}
              setValue={setValue}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {mutationErrorMessage && (
                <Typography color="error" gutterBottom align="center" paragraph>
                  {mutationErrorMessage}
                </Typography>
              )}
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                sx={{
                  backgroundColor: '#01B0BB',
                  borderRadius: 1,
                  alignSelf: 'end',
                }}
                endIcon={loading && <CircularProgress size={15} />}
                disabled={loading}
              >
                <Typography>CREAR ORDEN</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <AlertDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
          navigate('/orders');
        }}
        dialogTitle="Creación de orden"
        dialogContent={dialogContent}
      />
    </Box>
  );
}
export default NewOrderForm;
