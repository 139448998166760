import { useEffect, useRef, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import TableMui from '@mui/material/Table';
import TableContainerMui from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {
  useRowSelect,
  useExpanded,
  useTable,
  useSortBy,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter,
} from 'react-table';

import emptyDataImage from '../../assets/images/server-error.svg';
import sortParser from '../../helpers/sortParser';
import ExportDataForm from '../../views/commons/ExportDataForm';
import PaperBox from '../containers/PaperBox';
import TableError from '../Error';
import { addCheckbox, addEdit, addExpander } from './helper';
import Paginator from './Paginator';
import TableBody from './TableBody';
import TableBox from './TableBox';
import TableFilters from './TableFilters';
import TableHeader from './TableHeader';

function Table({
  report,
  data,
  columns,
  pagination: {
    hasPaginator,
    customPageSize,
    fetchMore, // if pagination is on the frontend side, fetchMore is false
    prefetch,
    queryVariables,
    pageSizeOptions,
    controlledPageCount,
  },
  filters,
  handleGlobalFilter, // TODO: delete and use currentTableStateVar
  initGlobalFilter,
  hasCheckbox,
  hasSearchBox,
  renderSubComponent,
  editFormProperties,
  loading,
  error,
  // styles
  sxPaper,
  paperBoxProps,
  disableSortBy,
  hasStickyHeader,
}) {
  const tableContainerRef = useRef();
  const firstUpdate = useRef(true);
  const [showEmptyData, setShowEmptyData] = useState(false);
  const [inputVariables, setInputVariables] = useState(queryVariables);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    page, // Instead of using 'rows', we'll use page (if pagination is active)
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: hasPaginator
          ? pageSizeOptions[0]
          : customPageSize || data.length,
      },
      manualGlobalFilter: !!fetchMore,
      manualPagination: !!fetchMore,
      ...(fetchMore && { pageCount: controlledPageCount }), // if table has server side pagination
      manualSortBy: !!fetchMore,
      disableSortBy,
      editFormProperties,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (hasCheckbox) {
        hooks.visibleColumns.push(addCheckbox);
      }
      if (renderSubComponent) {
        hooks.visibleColumns.push(addExpander);
      }
      if (editFormProperties) {
        hooks.visibleColumns.push(addEdit);
      }
    },
  );

  const fetchPage = async () => {
    await fetchMore({
      variables: {
        offset: pageSize * pageIndex,
        limit: pageSize,
        ...inputVariables,
      },
    });
  };

  const prefetchPage = () => {
    prefetch({
      variables: {
        offset: pageSize * (pageIndex + 1),
        limit: pageSize,
        ...inputVariables,
      },
    });
  };

  const onChangeSearchInput = ({ target: { value } }) => {
    setGlobalFilter(value);
    handleGlobalFilter(value);
  };
  const onFetchDataDebounced = useAsyncDebounce(fetchPage, 100);
  const onPrefetchDataDebounced = useAsyncDebounce(prefetchPage, 100);
  const onSetShowEmptyData = useAsyncDebounce(
    () => setShowEmptyData(!data.length && !loading && !error),
    500,
  );

  useEffect(() => {
    const { 0: order } = sortBy || [null];
    setInputVariables((prev) => ({
      ...prev,
      ...queryVariables,
      ...(!disableSortBy && { sort: sortParser(order) }),
    }));
  }, [sortBy, queryVariables]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (fetchMore) {
      onFetchDataDebounced();
    }
    if (prefetch) {
      onPrefetchDataDebounced();
    }
  }, [pageIndex, pageSize, inputVariables]);

  useEffect(() => {
    gotoPage(0);
  }, [pageSize, inputVariables]);

  useEffect(() => {
    onSetShowEmptyData();
    if (editFormProperties) {
      editFormProperties.setEditableRowIndex(-1);
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (customPageSize) {
      setPageSize(customPageSize);
    }
  }, [customPageSize]);

  return (
    <>
      {hasSearchBox && (
        <TableFilters
          value={globalFilter || initGlobalFilter}
          onChange={onChangeSearchInput}
          filters={filters.components}
          helperText={filters.helperTextSearchBox}
          handleClickOpenExportDataForm={
            report.hasReport
              ? report.reportProperties.dialogFunctions.handleClickOpen
              : false
          }
        />
      )}
      <PaperBox
        elevation={2}
        sx={{ width: '100%', overflow: 'hidden', ...sxPaper }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...paperBoxProps}
      >
        {loading && <LinearProgress color="secondary" />}
        <TableContainerMui
          ref={tableContainerRef}
          sx={{ ...(hasStickyHeader && { maxHeight: 'calc(100vh - 350px)' }) }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <TableMui stickyHeader {...getTableProps()}>
            <TableHeader headerGroups={headerGroups} />
            {data && !error && (
              <TableBody
                getTableBodyProps={getTableBodyProps}
                rows={page || rows}
                prepareRow={prepareRow}
                hasHover={hasCheckbox}
                visibleColumns={visibleColumns}
                renderSubComponent={renderSubComponent}
              />
            )}
          </TableMui>
        </TableContainerMui>
        {loading && !data.length && (
          <TableBox>
            <CircularProgress color="secondary" size={40} />
            <Typography>Cargando información</Typography>
          </TableBox>
        )}
        {error && (
          <TableBox>
            <TableError />
          </TableBox>
        )}
        {showEmptyData && (
          <TableBox>
            <TableError
              message="Parece que no encontramos datos"
              img={emptyDataImage}
            />
          </TableBox>
        )}
        {hasPaginator && (
          <Paginator
            tableContainerRef={tableContainerRef}
            pageIndex={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageSizeOptions={pageSizeOptions}
          />
        )}
      </PaperBox>
      {report.hasReport && (
        <ExportDataForm
          reportProperties={report.reportProperties}
          filterOptions={report.reportProperties.filterOptions}
          inputVariables={inputVariables}
          openExportDataForm={report.reportProperties.dialogFunctions.open}
          setOpenExportDataForm={
            report.reportProperties.dialogFunctions.setOpen
          }
          handleCloseExportDataForm={
            report.reportProperties.dialogFunctions.handleClose
          }
        />
      )}
    </>
  );
}

Table.propTypes = {
  report: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      mutation: PropTypes.object,
    }),
    PropTypes.bool,
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasCheckbox: PropTypes.bool,
  hasSearchBox: PropTypes.bool,
  renderSubComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  editFormProperties: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  pagination: PropTypes.oneOfType([
    PropTypes.shape({
      hasPaginator: PropTypes.bool,
      customPageSize: PropTypes.number,
      fetchMore: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
      prefetch: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
      pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  handleGlobalFilter: PropTypes.func,
  initGlobalFilter: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  loading: PropTypes.bool,
  filters: PropTypes.shape({
    components: PropTypes.node,
    helperTextSearchBox: PropTypes.string,
  }),
  // styles
  // eslint-disable-next-line react/forbid-prop-types
  sxPaper: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  paperBoxProps: PropTypes.object,
  disableSortBy: PropTypes.bool,
  hasStickyHeader: PropTypes.bool,
};

Table.defaultProps = {
  hasCheckbox: false,
  report: false,
  renderSubComponent: false,
  editFormProperties: false,
  pagination: {
    hasPaginator: true,
    customPageSize: null,
    fetchMore: false, // if pagination is on the frontend side, fetchMore is false
    prefetch: false,
    pageSizeOptions: [5, 10, 20, 50],
  },
  handleGlobalFilter: () => {},
  initGlobalFilter: '',
  hasSearchBox: true,
  loading: false,
  error: false,
  sxPaper: {},
  paperBoxProps: {},
  filters: { components: <div />, helperTextSearchBox: 'Buscar' },
  disableSortBy: true,
  hasStickyHeader: true,
};
export default Table;
