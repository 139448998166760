import { gql } from '@apollo/client';

const GET_RENTS = gql`
  query getRents($sellerId: String!) {
    rents(sellerId: $sellerId) {
      id
      name
      computedAddress {
        label
      }
    }
  }
`;

export default GET_RENTS;
