import PropTypes from 'prop-types';
import { useFormState } from 'react-hook-form';

import { REQUIRED_FIELD } from 'lib/form';

import ArrayFormTextField from './ArrayFormTextField';

function ArrayFormTextFieldCell({
  formArrayName,
  formItemHeaderName,
  currentItemIndex,
  control,
  register,
  required,
  inputType,
  valueAsNumber,
  validation,
  select,
  width,
  defaultValue,
  label,
  placeholder,
}) {
  const { errors } = useFormState({ control });
  const textFieldKey = `${formArrayName}[${currentItemIndex}].${formItemHeaderName}`;
  return (
    <ArrayFormTextField
      textFieldKey={textFieldKey}
      register={() =>
        register(textFieldKey, {
          ...(required && { required: REQUIRED_FIELD }),
          valueAsNumber,
          ...validation,
        })
      }
      required
      error={Boolean(
        errors &&
          errors[formArrayName] &&
          errors[formArrayName][currentItemIndex] &&
          errors[formArrayName][currentItemIndex][formItemHeaderName],
      )}
      inputType={inputType}
      select={select}
      width={width}
      defaultValue={defaultValue}
      label={label}
      placeholder={placeholder}
    />
  );
}

ArrayFormTextFieldCell.propTypes = {
  formArrayName: PropTypes.string,
  formItemHeaderName: PropTypes.string.isRequired,
  currentItemIndex: PropTypes.number.isRequired,
  control: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  inputType: PropTypes.string,
  valueAsNumber: PropTypes.bool,
  validation: PropTypes.shape({ min: PropTypes.number }),
  select: PropTypes.shape({
    select: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

ArrayFormTextFieldCell.defaultProps = {
  formArrayName: 'items',
  required: true,
  inputType: 'text',
  valueAsNumber: false,
  validation: {},
  select: { select: false },
  width: undefined,
  defaultValue: undefined,
  label: undefined,
  placeholder: undefined,
};
export default ArrayFormTextFieldCell;
