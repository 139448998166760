const keyMirror = require('keymirror');

const ORDER_STATUSES = keyMirror({
  created: null,
  pendingApproval: null,
  pending: null,
  processing: null,
  readyAtStore: null,
  invoiced: null,
  deliveryConfirmed: null,
  releasedToCarrier: null,
  outForDelivery: null,
  delivered: null,
  canceled: null,
  error: null,
  backorder: null,
});

const ORDER_STATUS_LABEL_MAPPING = {
  [ORDER_STATUSES.created]: 'Creada',
  [ORDER_STATUSES.pendingApproval]: 'Pendiente',
  [ORDER_STATUSES.pending]: 'Pendiente',
  [ORDER_STATUSES.processing]: 'En proceso',
  [ORDER_STATUSES.readyAtStore]: 'Lista en tienda',
  [ORDER_STATUSES.invoiced]: 'Lista en tienda',
  [ORDER_STATUSES.deliveryConfirmed]: 'Delivery confirmado',
  [ORDER_STATUSES.releasedToCarrier]: 'Entregado a transportista',
  [ORDER_STATUSES.outForDelivery]: 'En camino',
  [ORDER_STATUSES.delivered]: 'Entregada',
  [ORDER_STATUSES.canceled]: 'Cancelada',
  [ORDER_STATUSES.error]: 'Error',
  [ORDER_STATUSES.backorder]: 'Backorder',
};

export { ORDER_STATUSES, ORDER_STATUS_LABEL_MAPPING };
