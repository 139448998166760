import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Joyride, { STATUS } from 'react-joyride';

const STEPS = {
  next: 'Siguiente',
  back: 'Anterior',
  last: 'Última',
  skip: 'Saltar',
};

function TourGuide({ steps, run, setRun, finishTour }) {
  const theme = useTheme();
  const {
    palette: {
      secondary: { main },
    },
  } = theme;
  const stepsObject = steps.map((_step) => ({
    disableBeacon: true,
    spotlightClicks: true,
    styles: {
      options: {
        primaryColor: main,
        zIndex: 10000,
      },
    },
    ..._step,
  }));
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      finishTour();
    }
  };
  return (
    <Joyride
      steps={stepsObject}
      run={run}
      callback={handleJoyrideCallback}
      disableScrolling
      showProgress
      continuous
      showSkipButton
      locale={{
        next: STEPS.next,
        back: STEPS.back,
        last: STEPS.last,
        skip: STEPS.skip,
      }}
    />
  );
}

TourGuide.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
  run: PropTypes.bool,
  setRun: PropTypes.func.isRequired,
  finishTour: PropTypes.func,
};

TourGuide.defaultProps = {
  run: false,
  finishTour: () => {},
};
export default TourGuide;
