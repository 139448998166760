import { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { parse, isValid } from 'date-fns';
import * as Papa from 'papaparse';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ERRORS, LABEL_ERRORS, STATUS_CODES } from '../../apollo/serverErrors';
import AlertDialog from '../../components/dialogs/AlertDialog';
import FileInput from '../../components/input-files/FileInput';
import FileTemplate from '../../components/input-files/FileTemplate';
import PageTitle from '../../components/layout/PageTitle';
import FormTextField from '../../components/text-fields/FormTextField';
import { CREATE_REPLENISHMENT } from '../../gql/replenishments';
import FILE_TYPES from '../../lib/fileTypes';
import { REQUIRED_FIELD } from '../../lib/form';
import NewReplenishmentSellerWarehouse from '../forms/SellerWarehouseInputForm';
import ReplenishmentTable from './ReplenishmentTable';

const { REACT_APP_FILE_REPLENISHMENT_TEMPLATE_PATH } = process.env;
const DATE_FORMAT = 'dd/MM/yyyy';

function NewReplenishment() {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sellerId: '',
      warehouseId: '',
      comment: '',
      reference: '',
    },
  });
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'items',
    rules: { required: REQUIRED_FIELD },
  });
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const [dialogContent, setDialogContent] = useState('');
  const [mutationErrorMessage, setMutationErrorMessage] = useState();

  const [createReplenishment, { loading, error, reset }] = useMutation(
    CREATE_REPLENISHMENT,
    {
      onCompleted: (data) => {
        const {
          createReplenishment: { reference },
        } = data;
        reset();
        setOpenDialog(true);
        setDialogContent(
          `Revisa que el abastecimiento con número: ${reference} se haya creado correctamente. Puedes verlo en la vista de abastecimientos.`,
        );
      },
    },
  );

  const appendDataToForm = (tableData) => {
    tableData.forEach((row) => append(row));
  };

  const cellParser = {
    sku: (cellValue) => cellValue,
    quantity: (cellValue) => Number(cellValue),
    unitCost: (cellValue) => Number(cellValue),
    costCurrency: (cellValue) => cellValue,
    lotNumber: (cellValue) => cellValue,
    expirationDate: (cellValue) => {
      const parsedDate = parse(cellValue, DATE_FORMAT, new Date());
      return isValid(parsedDate) ? parsedDate : undefined;
    },
  };

  const readCSV = (files) => {
    const file = files[0];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transform: (cellValue, headerName) => cellParser[headerName](cellValue),
      complete: (result) => {
        const tableData = result.data;
        replace([]);
        appendDataToForm(tableData);
      },
    });
  };
  const onSubmit = (data) => {
    const { sellerId, warehouseId, items, reference, comment } = data;
    createReplenishment({
      variables: {
        input: { sellerId, warehouseId, items, reference, comment },
      },
    });
  };
  // Add custom hook to handle errors
  useEffect(() => {
    if (error) {
      if (error.message === ERRORS.INVALID_PRODUCTS_REPLENISHMENT_MESSAGE) {
        setMutationErrorMessage(
          LABEL_ERRORS.INVALID_PRODUCTS_REPLENISHMENT_MESSAGE,
        );
      } else if (error.networkError?.statusCode === STATUS_CODES.BAD_REQUEST) {
        setMutationErrorMessage(LABEL_ERRORS.USER_INPUT_REPLENISHMENT_MESSAGE);
      } else if (
        error.message === ERRORS.DUPLICATED_PRODUCTS_REPLENISHMENT_MESSAGE
      ) {
        setMutationErrorMessage(
          LABEL_ERRORS.DUPLICATED_PRODUCTS_REPLENISHMENT_MESSAGE,
        );
      } else {
        setMutationErrorMessage(
          LABEL_ERRORS.UNKNOW_ERROR_REPLENISHMENT_MESSAGE,
        );
      }
    }
  }, [error]);

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minWidth: 600,
        }}
      >
        <Box sx={{ pb: 1 }}>
          <Alert severity="warning">
            Recuerda agendar el abastecimiento con tu KAM
          </Alert>
        </Box>
        <PageTitle>Crear abastecimiento</PageTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <NewReplenishmentSellerWarehouse
            title="1. Vendedor y tienda"
            register={register}
            resetField={resetField}
            control={control}
            setValue={setValue}
          />
          <Divider flexItem />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              2. Archivo CSV con abastecimiento
            </Typography>
            <Divider flexItem />
            <FileTemplate
              filePath={REACT_APP_FILE_REPLENISHMENT_TEMPLATE_PATH}
              title="CSV de ejemplo"
            />
            <FileInput
              fileTypes={[FILE_TYPES.CSV]}
              fileLabel="CSV"
              fileParser={readCSV}
              error={Boolean(errors && errors.items)}
            />
            {!!fields.length && (
              <ReplenishmentTable
                control={control}
                register={register}
                setValue={setValue}
                replenishmentData={fields}
                remove={remove}
              />
            )}
            <Divider flexItem />
            <Typography variant="subtitle1">3. ID de referencia</Typography>
            <FormTextField
              fieldName="reference"
              label="ID de referencia"
              sx={{ width: '100%' }}
              control={control}
              register={() =>
                register('reference', { required: REQUIRED_FIELD })
              }
            />
          </Box>
          <Divider flexItem />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              4. Comentario (opcional)
            </Typography>
            <FormTextField
              fieldName="comment"
              control={control}
              label="Comentario"
              sx={{ width: '100%' }}
              register={() => register('comment')}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {mutationErrorMessage && (
              <Typography color="error" gutterBottom align="center" paragraph>
                {mutationErrorMessage}
              </Typography>
            )}
            {/* TODO: add reusable button component with handleSubmit */}
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={handleSubmit(onSubmit)}
              sx={(theme) => ({
                backgroundColor: theme.palette.submitButtonBackgroundColor.main,
                borderRadius: 1,
                alignSelf: 'end',
              })}
              endIcon={loading && <CircularProgress size={15} />}
              disabled={loading}
            >
              <Typography>INGRESAR ABASTECIMIENTO</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <AlertDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
          navigate('/replenishments');
        }}
        dialogTitle="Creación de abastecimiento"
        dialogContent={dialogContent}
      />
    </Box>
  );
}

export default NewReplenishment;
