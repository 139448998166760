import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import MenuProps from '../selects/styles';

function FormTextField({ ...props }) {
  const {
    fieldName,
    required,
    select,
    label,
    control,
    register,
    disabled,
    size,
    variant,
    helperText,
    type,
    inputProps,
    InputProps,
    placeholder,
  } = props;
  const { onChange, onBlur, name, ref } = register();
  const {
    field,
    formState: { errors },
  } = useController({
    name: fieldName,
    control,
  });

  const textFieldProps = {
    variant,
    onChange,
    onBlur,
    name,
    inputRef: ref,
    value: field.value,
    required,
    select: select.select,
    label,
    error: Boolean(errors[fieldName]),
    sx: { flexGrow: 1 },
    SelectProps: { MenuProps },
    inputProps,
    InputProps,
    helperText: helperText ?? (errors[fieldName] && errors[fieldName].message),
    disabled,
    size,
    type,
    placeholder,
  };

  if (!select.select) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TextField {...textFieldProps} />;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...textFieldProps}>
      {select.options.map(({ value: optionValue, label: optionLabel, img }) => (
        <MenuItem key={`${optionValue}`} value={optionValue}>
          {img ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <img loading="lazy" width="20" src={img} alt="" />
              <ListItemText>{optionLabel}</ListItemText>
            </Box>
          ) : (
            optionLabel
          )}
        </MenuItem>
      ))}
    </TextField>
  );
}

FormTextField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  select: PropTypes.shape({
    select: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        img: PropTypes.string,
      }),
    ),
  }),
  variant: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  size: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  inputProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  placeholder: PropTypes.string,
};
FormTextField.defaultProps = {
  required: true,
  select: { select: false },
  label: null,
  error: false,
  helperText: null,
  size: 'small',
  disabled: false,
  variant: 'outlined',
  type: 'text',
  inputProps: {},
  InputProps: {},
  placeholder: '',
};

export default FormTextField;
