import { Route, Routes, Navigate } from 'react-router-dom';

import ProtectedRoute from 'routes/ProtectedRoute';
import Auth from 'views/Auth';
import MainCatalog from 'views/catalogs';
import ListInventory from 'views/catalogs/inventories/';
import NotAuthorized from 'views/NotAuthorized';
import NewOrderForm from 'views/orders/forms/new';
import NewBatchOrdersForm from 'views/orders/forms/new-batch';
import ListOrders from 'views/orders/ListOrders';
import NewReplenishmentForm from 'views/replenishments/NewReplenishmentForm';
import ReplenishmentList from 'views/replenishments/ReplenishmentList';
import Replenishment from 'views/replenishments/show-replenishment/Replenishment';

function AppRoutes() {
  return (
    <Routes>
      <Route>
        <Route path="/orders">
          <Route
            index
            element={
              <ProtectedRoute>
                <ListOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="new"
            element={
              <ProtectedRoute>
                <NewOrderForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="new-batch"
            element={
              <ProtectedRoute>
                <NewBatchOrdersForm />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/inventory"
          element={
            <ProtectedRoute>
              <ListInventory />
            </ProtectedRoute>
          }
        />
        <Route path="/catalogs">
          <Route index element={<Navigate to="/catalogs/products" />} />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <MainCatalog />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/replenishments">
          <Route
            path="new"
            element={
              <ProtectedRoute>
                <NewReplenishmentForm />
              </ProtectedRoute>
            }
          />
          <Route
            index
            element={
              <ProtectedRoute>
                <ReplenishmentList />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute>
                <Replenishment />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/orders" />} />
      </Route>
      <Route path="/auth" element={<Auth />} />
      <Route path="/auth_error" element={<NotAuthorized />} />
    </Routes>
  );
}

export default AppRoutes;
