import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import SimpleSelect from '../selects/SimpleSelect';

function Paginator({
  pageSizeOptions, // total rows per page
  pageCount, // total pages
  pageIndex,
  pageSize,
  setPageSize,
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  tableContainerRef,
}) {
  const scrollContainer = () => {
    tableContainerRef.current.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 2,
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography>Filas por página: </Typography>
        <SimpleSelect
          id="paginator-simple-select"
          options={pageSizeOptions.map((size) => ({
            value: size,
            label: size,
          }))}
          controlled={{
            controlledValue: pageSize,
            setControlledValue: setPageSize,
          }}
        />
      </Box>
      <Typography>
        {pageIndex + 1} - {pageCount}
      </Typography>
      <Box>
        <Tooltip title="Ir a página anterior">
          <span>
            <IconButton
              onClick={() => {
                previousPage();
                scrollContainer();
              }}
              disabled={!canPreviousPage}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Ir a página siguiente">
          <span>
            <IconButton
              onClick={() => {
                nextPage();
                scrollContainer();
              }}
              disabled={!canNextPage}
            >
              <ChevronRightIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}

Paginator.propTypes = {
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableContainerRef: PropTypes.object.isRequired,
};

export default Paginator;
