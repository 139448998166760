import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { globalLoaderVar } from '../../apollo/reactiveVariables';
import GET_FILTERS from '../../gql/filters';

function ProtectedView({ children }) {
  const { loading } = useQuery(GET_FILTERS);
  useEffect(() => {
    globalLoaderVar(loading);
  }, [loading]);
  return children;
}

ProtectedView.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ProtectedView;
