import { useEffect, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useWatch, useFormState } from 'react-hook-form';

import { GET_PRODUCT_AT_WAREHOUSE_BY_IDS } from '../../../../../gql/catalogs';
import {
  REQUIRED_FIELD,
  POSITIVE_NUMBER,
  QUANTITY_AVAILABLE_EXCEEDED,
} from '../../../../../lib/form';

const UNDEFINED_QUANTITY_AVAILABLE = 0;

function ItemQuantityCell({
  control,
  productId,
  fields,
  prevFields,
  register,
}) {
  const currentItemIndex = useMemo(
    () => fields.findIndex((item) => item.value === productId),
    [fields, productId],
  );

  const [
    getProductAtWarehouse,
    { loading: loadingInventory, error: errorInventory, data: dataInventory },
  ] = useLazyQuery(GET_PRODUCT_AT_WAREHOUSE_BY_IDS);

  const quantityAvailable = useMemo(
    () =>
      dataInventory?.getProductAtWarehouseByIds?.quantityAvailable ??
      UNDEFINED_QUANTITY_AVAILABLE,
    [dataInventory],
  );

  const { onChange, onBlur, name, ref } = register(
    `items[${currentItemIndex}].quantity`,
    {
      required: REQUIRED_FIELD,
      min: {
        value: 1,
        message: POSITIVE_NUMBER,
      },
      max: {
        value: quantityAvailable,
        message: QUANTITY_AVAILABLE_EXCEEDED,
      },
    },
  );
  const { errors } = useFormState({ control });
  const watchSellerId = useWatch({
    name: 'sellerId',
    control,
  });
  const watchWarehouseId = useWatch({
    name: 'warehouseId',
    control,
  });
  const colorQuantityAvailable = useMemo(
    () => (quantityAvailable ? undefined : 'error'),
    [quantityAvailable],
  );

  useEffect(() => {
    const isLastItem =
      currentItemIndex + 1 === fields.length &&
      fields.length > prevFields.length;
    getProductAtWarehouse({
      variables: {
        sellerId: watchSellerId,
        warehouseId: watchWarehouseId,
        productId,
      },
      fetchPolicy: isLastItem ? 'cache-and-network' : 'cache-first',
    });
  }, [fields, prevFields, currentItemIndex]);

  if (loadingInventory) return <CircularProgress size={25} color="secondary" />;
  if (errorInventory)
    return (
      <Typography color="error" variant="caption">
        Error para validar inventario
      </Typography>
    );
  return (
    <Box
      key={`quantity-item${productId}-box`}
      sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
    >
      <TextField
        ref={ref}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        key={`items[${currentItemIndex}].quantity`}
        sx={{ width: 80 }}
        size="small"
        type="number"
        error={Boolean(
          errors.items && errors.items[currentItemIndex]?.quantity,
        )}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="caption" color={colorQuantityAvailable}>
          {quantityAvailable} unidades disponibles
        </Typography>
        <Typography variant="caption" color={colorQuantityAvailable}>
          {dataInventory?.getProductAtWarehouseByIds?.lastUpdate}
        </Typography>
      </Box>
    </Box>
  );
}
ItemQuantityCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prevFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  register: PropTypes.func.isRequired,
};
export default ItemQuantityCell;
