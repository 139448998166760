import { useMemo, useState } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';

function OrdersDataErrors({ errors }) {
  const [showDetails, setShowDetails] = useState(false);
  const errorMessages = useMemo(
    () =>
      errors.reduce(
        (acc, current) => ({
          ...acc,
          [current.order.orderNumber]: [
            ...(acc[current.order.orderNumber] || []),
            current,
          ],
        }),
        {},
      ),
    [errors],
  );
  return (
    <Alert
      severity="error"
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => setShowDetails((prev) => !prev)}
        >
          {showDetails ? 'OCULTAR ÓRDENES CON ERROR' : 'VER ÓRDENES CON ERROR'}
        </Button>
      }
    >
      <AlertTitle>
        Las siguientes órdenes cargadas presentan errores:
      </AlertTitle>
      Revisa el excel de ejemplo para corregirlas
      {showDetails && (
        <List>
          {Object.keys(errorMessages).map((orderNumber) => (
            <ListItem sx={{ paddingX: 0 }}>
              <ListItemText secondary={orderNumber} />
            </ListItem>
          ))}
        </List>
      )}
    </Alert>
  );
}
OrdersDataErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.shape({ orderNumber: PropTypes.string }).isRequired,
      message: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ),
};
OrdersDataErrors.defaultProps = {
  errors: [],
};
export default OrdersDataErrors;
