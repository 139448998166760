import { useState } from 'react';

import readExcel from 'lib/files/readExcel';
import validateColumnExcelData from 'lib/files/validateColumnExcelData';
import validateExcelHeaders from 'lib/files/validateExcelHeaders';

import TEMPLATE_EXCEL_HEADERS from '../lib/templateExcelHeaders';
import translateOrders from '../lib/translateOrders';
import ordersSchema from '../lib/validateOrdersSchema';

const COLUMN_ID = 'orderNumber';

function useUploadOrders({
  setValue,
  createBatchOrders,
  watchSellerId,
  watchWarehouseId,
}) {
  const [orders, setOrders] = useState([]);
  const [validationErrors, setValidationErrors] = useState();
  const onCompleteReadFile = ({
    data: tableData,
    success: readFileSuccess,
  }) => {
    const isValidRowId = validateColumnExcelData({
      excelData: tableData,
      column: COLUMN_ID,
      validate: (value) => Boolean(value),
    });
    const success = isValidRowId && readFileSuccess;
    if (success) {
      const fileOrders = translateOrders({
        tableData,
        watchSellerId,
        watchWarehouseId,
      });
      const currentOrders = Object.values(fileOrders);
      setOrders(Object.values(fileOrders));
      ordersSchema
        .validate(Object.values(fileOrders), {
          abortEarly: false,
        })
        .then(() => {
          setValue(
            'isExcelValid',
            {
              uploaded: true,
              headers: true,
              data: Boolean(currentOrders.length),
              rowId: true,
            },
            { shouldValidate: true },
          );
        })
        .catch(function onError(err) {
          const dataErrors = err.errors.map((validationError) => ({
            ...validationError,
            order: Object.values(fileOrders)[validationError.index],
          }));
          setValidationErrors(dataErrors);
        });
      setValue(
        'isExcelValid',
        {
          uploaded: true,
          headers: true,
          data: false,
          rowId: true,
        },
        { shouldValidate: true },
      );
    } else {
      setOrders([]);
      if (!readFileSuccess) {
        setValue(
          'isExcelValid',
          { uploaded: true, headers: false, data: true, rowId: true },
          { shouldValidate: true },
        );
      } else {
        setValue(
          'isExcelValid',
          {
            uploaded: true,
            headers: true,
            data: true,
            rowId: false,
          },
          { shouldValidate: true },
        );
      }
    }
  };
  const validateWorksheet = (worksheet) => {
    const isValid = validateExcelHeaders({
      worksheet,
      templateHeaders: TEMPLATE_EXCEL_HEADERS,
    });
    return isValid;
  };
  const onSubmit = () => {
    const currentOrders = orders.map((order) => ({
      ...order,
      sellerId: watchSellerId,
      warehouseId: watchWarehouseId,
    }));
    createBatchOrders({ variables: { input: currentOrders } });
  };
  const removeOrder = (inputOrderNumber) => {
    setOrders((prev) =>
      prev.filter(({ orderNumber }) => orderNumber !== inputOrderNumber),
    );
  };
  const fileParser = (files) =>
    readExcel(files, validateWorksheet, onCompleteReadFile);

  return { orders, onSubmit, fileParser, removeOrder, validationErrors };
}
export default useUploadOrders;
