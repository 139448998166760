import { useMemo, useState, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  orderFilterVar,
  seenTourListOrdersVar,
} from '../../apollo/reactiveVariables';
import PageTitle from '../../components/layout/PageTitle';
import { showTourGuideListOrdersLocalStorage } from '../../components/onboarding/helpers';
import TourGuide from '../../components/onboarding/TourGuide';
import SkeletonListData from '../../components/skeletons/ListData';
import GET_FILTERS from '../../gql/filters';
import GET_ORDERS from '../../gql/orders';
import { GENERATE_ORDERS_REPORT } from '../../gql/reports';
import { hasCustomerInfo } from '../../helpers/customerParser';
import STATUS_COLOR_MAPPING from '../../helpers/statusColorMapping';
import getDeliveryLogo from '../../lib/logos/deliveryLogo';
import getPlatformLogo from '../../lib/logos/platformLogo';
import { ORDER_STATUS_LABEL_MAPPING } from '../../lib/orderStatuses';
import { SHIPPING_METHODS_LABEL_MAPPING } from '../../lib/shippingMethods';
import HeaderListData from '../commons/HeaderListData';
import ListServerData from '../commons/ListServerData';
import AttachedFiles from './AttachedFilesMenu';
import OrderFilters from './OrderFilters';
import OrderItems from './OrderItems';

const { REACT_APP_VELA_URL } = process.env;
const NEW_ORDER_ROUTE = '/orders/new';

function ListOrders() {
  const orderFilter = useReactiveVar(orderFilterVar);
  const seenTourListOrders = useReactiveVar(seenTourListOrdersVar);

  const TOUR_STEPS = [
    {
      title: 'Órdenes manuales',
      target: '#createOrderButton',
      content: 'Ahora puedes crear órdenes manuales',
    },
    {
      title: 'Órdenes',
      target: '#pageTitle',
      content: 'Puedes visualizar las órdenes de tus canales integrados',
    },
    {
      title: 'Filtros',
      target: '#filterBox',
      content:
        'Puedes filtrar por vendedor, tienda, rango de fecha de ingreso y buscar por orden',
    },
    {
      title: 'Ordenar',
      target: '#tableSort',
      content: 'Puedes ordenar por fecha de ingreso de la orden',
    },
    {
      title: 'Productos',
      target: '#tableSubRow',
      content: 'Puedes ver los productos de la orden',
    },
    {
      title: 'Boleta',
      target: '#invoiceButton',
      content: 'Puedes visualizar la boleta si está en nuestro sistema',
    },
    {
      title: 'Tracking',
      target: '#trackingButton',
      content: 'Puedes ver el seguimiento del pedido que ve tu cliente',
    },
    {
      title: 'Exportar',
      target: '#exportButton',
      content:
        'Puedes exportar los datos con los filtros seleccionados actuales. Se enviarán por mail en formato excel',
    },
  ];
  const [run, setRun] = useState(!seenTourListOrders);
  const [filterOptions, setFilterOptions] = useState({
    warehouses: [],
    sellers: [],
  });
  const [filters, setFilters] = useState(orderFilter);
  const [queryVariables, setQueryVariables] = useState(orderFilter);

  const { loading } = useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        orderFilterOptions: { sellers: _sellers, warehouses: _warehouses },
      },
    }) => {
      const sellers = _sellers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      const warehouses = _warehouses.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFilterOptions({ sellers, warehouses });
      setFilters((prev) => ({
        ...prev,
        warehouseId:
          prev.warehouseId.length > 0 ? prev.warehouseId : [_warehouses[0]?.id],
        sellerId: prev.sellerId.length > 0 ? prev.sellerId : [_sellers[0]?.id],
      }));
    },
  });
  useEffect(() => {
    orderFilterVar(filters);
  }, [filters]);

  useEffect(() => {
    setQueryVariables({
      ...filters,
      warehouseId:
        filters.warehouseId.length > 0 ? filters.warehouseId : undefined,
      sellerId: filters.sellerId.length > 0 ? filters.sellerId : undefined,
    });
  }, [filters]);

  const [openExportDataForm, setOpenExportDataForm] = useState(false);
  const handleClickOpenExportDataForm = () => {
    setOpenExportDataForm(true);
  };
  const handleCloseExportDataForm = () => {
    setOpenExportDataForm(false);
  };
  const report = {
    hasReport: true,
    reportProperties: {
      mutation: GENERATE_ORDERS_REPORT,
      type: 'orders',
      dialogFunctions: {
        open: openExportDataForm,
        setOpen: setOpenExportDataForm,
        handleClickOpen: handleClickOpenExportDataForm,
        handleClose: handleCloseExportDataForm,
      },
      filterOptions,
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: <HeaderListData>#Orden</HeaderListData>,
        accessor: 'sourceOrderId',
        disableSortBy: true,
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: {
              // eslint-disable-next-line react/prop-types
              seller,
              // eslint-disable-next-line react/prop-types
              system: { id },
            },
          },
        }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box
              component="img"
              sx={{
                width: 120,
              }}
              alt={id}
              src={getPlatformLogo(id)}
            />
            <Typography>{value}</Typography>
            {/* eslint-disable-next-line react/prop-types */}
            <Typography variant="caption">{seller.name}</Typography>
          </Box>
        ),
      },
      {
        Header: <HeaderListData>Fecha ingreso</HeaderListData>,
        accessor: 'createdAt',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => <Typography variant="caption">{value}</Typography>,
      },
      {
        Header: <HeaderListData>Tipo</HeaderListData>,
        accessor: 'businessType',
        disableSortBy: true,
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: {
              // eslint-disable-next-line react/prop-types
              businessType,
            },
          },
        }) => (
          <Box sx={{ display: 'flex', alignContent: 'center', gap: 1 }}>
            <Chip label={businessType} size="small" />
          </Box>
        ),
      },
      {
        Header: <HeaderListData>Estado</HeaderListData>,
        accessor: 'status',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value: { value } }) => (
          <Tooltip title="La orden tiene esta secuencia de estados: pendiente, procesando, lista en tienda, delivery solicitado, entregado a transportista, en camino y entregada.">
            <Chip
              label={
                <Typography sx={{ fontWeight: 500 }}>
                  {ORDER_STATUS_LABEL_MAPPING[value]}
                </Typography>
              }
              color={STATUS_COLOR_MAPPING[value]}
              variant="outlined"
            />
          </Tooltip>
        ),
      },
      {
        Header: <HeaderListData>Boleta</HeaderListData>,
        accessor: 'invoiceURL',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) =>
          value ? (
            <Tooltip title="Descargar boleta">
              <IconButton
                id="invoiceButton"
                variant="body2"
                href={value}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DescriptionOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Sin boleta en sistema">
              <span id="invoiceButton">
                <PendingActionsOutlinedIcon color="primary" />
              </span>
            </Tooltip>
          ),
      },
      {
        Header: <HeaderListData>Tipo de entrega</HeaderListData>,
        accessor: 'delivery',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value: { shippingMethod, systemName } }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {systemName && (
              <Box
                component="img"
                sx={{
                  height: 'auto',
                  width: 70,
                }}
                alt={systemName}
                src={getDeliveryLogo(systemName)}
              />
            )}
            <Typography noWrap>
              {SHIPPING_METHODS_LABEL_MAPPING[shippingMethod]}
            </Typography>
          </Box>
        ),
      },
      {
        Header: <HeaderListData>Tracking</HeaderListData>,
        accessor: 'id',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <Tooltip title="Ver URL de seguimiento">
            <IconButton
              id="trackingButton"
              variant="body2"
              href={`${REACT_APP_VELA_URL}/${value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LocalShippingOutlinedIcon />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        Header: <HeaderListData>Cliente</HeaderListData>,
        accessor: 'customer',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) =>
          hasCustomerInfo(value) ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="caption">
                {/* eslint-disable-next-line react/prop-types */}
                {value.firstName} {value.lastName}
              </Typography>
              <Typography variant="caption">
                {/* eslint-disable-next-line react/prop-types */}
                {value.email} {value.phone}
              </Typography>
            </Box>
          ) : (
            <Typography variant="caption">Desconocido</Typography>
          ),
      },
      {
        Header: <HeaderListData>Tienda</HeaderListData>,
        accessor: 'warehouse',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value: { name } }) => <Typography>{name}</Typography>,
      },
      {
        Header: <HeaderListData>Archivos</HeaderListData>,
        accessor: 'attachedFiles',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <AttachedFiles attachedFiles={value} />,
      },
    ],
    [],
  );

  const renderSubComponent = (row) => {
    const {
      original: { items },
    } = row;
    return <OrderItems items={items} />;
  };

  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({ ...prev, globalFilter }));
  };
  if (loading)
    return (
      <>
        <PageTitle>Órdenes</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Box>
          <PageTitle
            handleClick={() => {
              setRun(true);
            }}
          >
            Órdenes
          </PageTitle>
          <TourGuide
            steps={TOUR_STEPS}
            run={run}
            setRun={setRun}
            finishTour={showTourGuideListOrdersLocalStorage}
          />
        </Box>
        <Button
          id="createOrderButton"
          startIcon={<AddIcon />}
          color="secondary"
          href={NEW_ORDER_ROUTE}
          target="_blank"
          rel="noopener noreferrer"
        >
          CREAR ORDEN
        </Button>
      </Box>
      <ListServerData
        report={report}
        query={GET_ORDERS}
        filters={{
          components: (
            <OrderFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
              warehousesOptions={filterOptions.warehouses}
            />
          ),
          helperTextSearchBox:
            'Buscar por número, estado, tipo de entrega, cliente o canal de venta de la orden',
        }}
        queryVariables={{ filters: queryVariables }}
        dataKey="getOrders"
        columns={columns}
        filterOptions={filterOptions}
        renderSubComponent={renderSubComponent}
        handleGlobalFilter={handleGlobalFilter}
        initGlobalFilter={orderFilter.globalFilter}
      />
    </>
  );
}

export default ListOrders;
