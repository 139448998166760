import {
  seenTourListOrdersVar,
  seenTourListInventoryVar,
} from '../../apollo/reactiveVariables';

function showTourGuideListOrdersLocalStorage() {
  localStorage.setItem('seenTourListOrders', 'true');
  seenTourListOrdersVar(true);
}
function showTourGuideListInventoryLocalStorage() {
  localStorage.setItem('seenTourListInventory', 'true');
  seenTourListInventoryVar(true);
}
function showTourGuideListProductsLocalStorage() {
  localStorage.setItem('seenTourListProducts', 'true');
  seenTourListInventoryVar(true);
}
export {
  showTourGuideListOrdersLocalStorage,
  showTourGuideListInventoryLocalStorage,
  showTourGuideListProductsLocalStorage,
};
