import { useState, useEffect } from 'react';

import { useReactiveVar, useQuery } from '@apollo/client';

import GET_FILTERS from '../gql/filters';

function useGetFilters({ filterVar, filterOptionsKey = 'orderFilterOptions' }) {
  const filter = filterVar
    ? useReactiveVar(filterVar)
    : { warehouseId: null, sellerId: null };

  const [filterOptions, setFilterOptions] = useState({
    warehouses: [],
    sellers: [],
  });
  const [filters, setFilters] = useState(filter);

  const { loading } = useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        [filterOptionsKey]: { sellers, warehouses },
      },
    }) => {
      const sellersOptions = sellers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      const warehousesOptions = warehouses.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFilterOptions({
        sellers: sellersOptions,
        warehouses: warehousesOptions,
      });
      setFilters((prev) => ({
        ...prev,
        warehouseId: filter.warehouseId || warehousesOptions[0]?.value,
        sellerId: filter.sellerId || sellersOptions[0]?.value,
      }));
    },
  });

  useEffect(() => {
    if (filterVar) {
      filterVar(filters);
    }
  }, [filters]);

  /* filterOptions are the options for the select component
  filters and setFilters are the filters or query variables for the current query */
  return { loading, filterOptions, filters, setFilters };
}
export default useGetFilters;
