import { gql } from '@apollo/client';

const GET_ORDERS = gql`
  query getOrders(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: OrderFilters
  ) {
    getOrders(offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      nodes {
        id
        createdAt
        sourceOrderId
        businessType
        status {
          value
        }
        createdAt
        invoiceURL
        warehouse {
          id
          name
        }
        seller {
          id
          name
        }
        system {
          id
        }
        delivery {
          systemName
          shippingMethod
        }
        customer {
          firstName
          lastName
          phone
          email
        }
        items {
          id
          sku
          description
          quantity
        }
        attachedFiles {
          key
          title
        }
      }
      total
      pageCount
    }
  }
`;
const CREATE_ORDER = gql`
  mutation Mutation($input: CreateOrderInput!) {
    createOrder(input: $input) {
      sourceOrderId
    }
  }
`;
const CREATE_BATCH_ORDERS = gql`
  mutation Mutation($input: [CreateBatchOrderInput!]!) {
    createBatchOrders(input: $input) {
      success
    }
  }
`;
export default GET_ORDERS;
export { CREATE_ORDER, CREATE_BATCH_ORDERS };
