import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: theme.palette.submitButtonBackgroundColor.main,
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: theme.palette.submitButtonBackgroundColor.hover,
  },
  alignSelf: 'end',
}));

export default SubmitButton;
