import { useState } from 'react';

import { useQuery } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import { Navigate } from 'react-router-dom';

import { getToken, AUTHENTICATION_ERROR_MESSAGE } from '../auth/utils';
import Error from '../components/Error';
import useQueryParams from '../custom-hooks/useQueryParams';
import IS_LOGGED_IN from '../gql/auth';

function Auth() {
  const [error, setError] = useState(false);
  const params = useQueryParams();
  const code = params.get('code');
  const { data } = useQuery(IS_LOGGED_IN, {
    onError: (_error) => {
      if (_error.message === AUTHENTICATION_ERROR_MESSAGE) {
        getToken(code)
          .then(({ data: tokenData }) => {
            Object.keys(tokenData).map((key) =>
              localStorage.setItem(key, tokenData[key]),
            );
            window.location.replace('/');
          })
          .catch((tokenError) => {
            setError(!!tokenError);
          });
      }
    },
  });
  const { isLoggedIn } = data || {};
  if (isLoggedIn) return <Navigate to="/" />;
  if (error)
    return (
      <Error message="Ocurrió un error al iniciar sesión. Intenta en un rato" />
    );
  return <LinearProgress color="secondary" />;
}

export default Auth;
