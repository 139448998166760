import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

function EditButtons({
  currentIndex,
  editableRowIndex,
  loading,
  clickEdit,
  clickSave,
  clickUndo,
  errors,
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
      {!(currentIndex === editableRowIndex) && (
        <Tooltip title="Editar">
          <IconButton
            id="editButton"
            onClick={clickEdit}
            sx={{
              '&:hover': {
                color: 'green',
              },
            }}
          >
            <EditOutlinedIcon sx={{ m: 0 }} />
          </IconButton>
        </Tooltip>
      )}
      {currentIndex === editableRowIndex && !loading && (
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
          <IconButton id="undoButton" onClick={clickUndo} size="small">
            <UndoOutlinedIcon />
          </IconButton>
          <IconButton
            id="saveButton"
            onClick={clickSave}
            disabled={Object.keys(errors).length !== 0}
            color="success"
            size="small"
          >
            <SaveOutlinedIcon />
          </IconButton>
        </Box>
      )}

      {currentIndex === editableRowIndex && loading && (
        <CircularProgress sx={{ mb: 2 }} color="success" />
      )}
    </Box>
  );
}

EditButtons.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  clickEdit: PropTypes.func.isRequired,
  clickSave: PropTypes.func.isRequired,
  clickUndo: PropTypes.func.isRequired,
};
export default EditButtons;
