import { useState, useMemo, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import SimpleMenu from '../../components/menus/SimpleMenu';
import { GET_PRESIGNED_GET_FILE_URL } from '../../gql/attachments';

function AttachedFilesMenu({ attachedFiles }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getPresignedGetFileUrl] = useLazyQuery(GET_PRESIGNED_GET_FILE_URL);
  const showButton = useMemo(() => !!attachedFiles.length, [attachedFiles]);

  useEffect(() => {
    if (anchorEl) {
      setLoading(true);
      Promise.all(
        attachedFiles.map((file) =>
          getPresignedGetFileUrl({ variables: { key: file.key } }),
        ),
      ).then((response) => {
        const files = response.map(
          ({
            data: {
              presignedGetFileUrl: { key, presignedUrl },
            },
          }) => ({
            id: key,
            label: attachedFiles.find((file) => file.key === key).title,
            href: presignedUrl,
            missingMessage: 'Buscando archivo',
          }),
        );
        setOptions(files);
        setLoading(false);
      });
    }
  }, [attachedFiles, anchorEl]);

  const renderButton = (props) => (
    <Tooltip
      title={
        showButton ? 'Ver archivos adjuntos' : 'No existen archivos adjuntos'
      }
    >
      <span>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <IconButton {...props} disabled={!showButton || loading}>
          {loading ? (
            <CircularProgress color="secondary" size={20} />
          ) : (
            <MoreVertIcon />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );

  return (
    <SimpleMenu
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      options={options}
      renderButton={renderButton}
    />
  );
}
AttachedFilesMenu.propTypes = {
  attachedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
};

AttachedFilesMenu.defaultProps = {
  attachedFiles: [],
};
export default AttachedFilesMenu;
