import { useEffect, useMemo } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import useUploadFile from '../../custom-hooks/useUploadFile';
import {
  GET_PRESIGNED_GET_FILE_URL,
  GET_PRESIGNED_PUT_FILE_URL,
} from '../../gql/attachments';
import ArrayFormTextFieldCell from '../text-fields/ArrayFormTextFieldCell';

function FileUpload({
  file,
  handleClose,
  fileIndex,
  control,
  register,
  setValue,
}) {
  const [
    uploadFile,
    {
      loading: loadingUploadFile,
      error: errorUploadFile,
      completed: completedUploadFile,
    },
  ] = useUploadFile();

  const [
    getPresignedGetFileUrl,
    {
      data: dataGetFileUrl,
      loading: loadingGetFileUrl,
      error: errorGetFileUrl,
    },
  ] = useLazyQuery(GET_PRESIGNED_GET_FILE_URL, { fetchPolicy: 'network-only' });

  const {
    data: dataPutFileUrl,
    loading: loadingPutFileUrl,
    error: errorLoadingPutFileUrl,
  } = useQuery(GET_PRESIGNED_PUT_FILE_URL, {
    variables: { fileName: file.name },
    onCompleted: ({ presignedPutFileUrl }) => {
      const { key, presignedUrl } = presignedPutFileUrl;
      setValue(`attachments[${fileIndex}].key`, key);
      uploadFile(file, presignedUrl);
    },
  });

  const error = useMemo(
    () => Boolean(errorUploadFile || errorGetFileUrl || errorLoadingPutFileUrl),
    [errorUploadFile, errorGetFileUrl, errorLoadingPutFileUrl],
  );

  useEffect(() => {
    if (completedUploadFile) {
      getPresignedGetFileUrl({
        variables: { key: dataPutFileUrl.presignedPutFileUrl.key },
        fetchPolicy: 'network-only',
      });
    }
  }, [completedUploadFile]);

  return (
    <Box
      key={file.name}
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {(loadingPutFileUrl || loadingGetFileUrl || loadingUploadFile) && (
        <LinearProgress color="secondary" />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{file.name}</Typography>
          {!error && dataGetFileUrl?.presignedGetFileUrl?.presignedUrl && (
            <Tooltip title="Ver archivo">
              <IconButton
                variant="outlined"
                href={dataGetFileUrl.presignedGetFileUrl.presignedUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FileDownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Tooltip title="Eliminar archivo">
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {!error && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ArrayFormTextFieldCell
            formArrayName="attachments"
            formItemHeaderName="title"
            currentItemIndex={fileIndex}
            control={control}
            register={register}
            width="100%"
            label="Descripción del archivo"
            placeholder="Ejemplo: Factura electrónica"
            required
          />
        </Box>
      )}
      {error && (
        <Typography color="error">
          {' '}
          Hubo un error para cargar el archivo
        </Typography>
      )}
    </Box>
  );
}
FileUpload.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  fileIndex: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
export default FileUpload;
