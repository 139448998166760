import { gql } from '@apollo/client';

const GET_FILTERS = gql`
  query getFilters($limit: Int = 1000) {
    getFilters(limit: $limit) {
      orderFilterOptions {
        sellers {
          id
          name
        }
        warehouses {
          id
          name
        }
      }
      catalogFilterOptions {
        sellers {
          id
          name
        }
        warehouses {
          id
          name
        }
      }
    }
  }
`;

export default GET_FILTERS;
