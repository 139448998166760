import keyMirror from 'keymirror';

const REPLENISHMENT_STATUSES = keyMirror({
  created: null,
  received: null,
});

const REPLENISHMENT_STATUS_LABEL_MAPPING = {
  [REPLENISHMENT_STATUSES.created]: 'Creado',
  [REPLENISHMENT_STATUSES.received]: 'Recibido',
};

const REPLENISHMENT_STATUS_COLOR_MAPPING = {
  created: 'info',
  received: 'success',
};

export {
  REPLENISHMENT_STATUSES,
  REPLENISHMENT_STATUS_LABEL_MAPPING,
  REPLENISHMENT_STATUS_COLOR_MAPPING,
};
