import keyMirror from 'keymirror';

import { CURRENCIES } from './currency';

const SALES_TAX = keyMirror({
  IVA: null,
  IGV: null,
});

const CURRENCIES_SALES_TAX_MAPPER = {
  [CURRENCIES.CLP]: SALES_TAX.IVA,
  [CURRENCIES.PEN]: SALES_TAX.IGV,
};

export { SALES_TAX, CURRENCIES_SALES_TAX_MAPPER };
