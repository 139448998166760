import { useMemo, useState, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  inventoryFilterVar,
  seenTourListInventoryVar,
} from 'apollo/reactiveVariables';
import ChipNumber from 'components/chips/ChipNumber';
import PageTitle from 'components/layout/PageTitle';
import { showTourGuideListInventoryLocalStorage } from 'components/onboarding/helpers';
import TourGuide from 'components/onboarding/TourGuide';
import SkeletonListData from 'components/skeletons/ListData';
import GET_FILTERS from 'gql/filters';
import GET_INVENTORY from 'gql/inventory';
import { GENERATE_PRODUCTS_AT_WAREHOUSE_REPORT } from 'gql/reports';
import HeaderListData from 'views/commons/HeaderListData';
import ListServerData from 'views/commons/ListServerData';

import InventoryFilters from './components/InventoryFilters';

function ListInventory() {
  const inventoryFilter = useReactiveVar(inventoryFilterVar);
  const seenTourListInventory = useReactiveVar(seenTourListInventoryVar);

  const TOUR_STEPS = [
    {
      title: 'Inventario',
      target: '#pageTitle',
      content:
        'Ahora puedes visualizar el inventario en tiempo real de cada tienda',
    },
    {
      title: 'Filtros',
      target: '#filterBox',
      content:
        'Puedes filtrar por vendedor, tienda y buscar por sku, producto y cantidad disponible',
    },
    {
      title: 'Exportar',
      target: '#exportButton',
      content:
        'Puedes exportar los datos con los filtros seleccionados actuales. Se enviarán por mail en formato excel',
    },
  ];
  const [run, setRun] = useState(!seenTourListInventory);
  const [filterOptions, setFilterOptions] = useState({
    warehouses: [],
    sellers: [],
  });

  const [filters, setFilters] = useState(inventoryFilter);
  useEffect(() => {
    inventoryFilterVar(filters);
  }, [filters]);

  const { loading } = useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        catalogFilterOptions: { sellers: _sellers, warehouses: _warehouses },
      },
    }) => {
      const sellers = _sellers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      const warehouses = _warehouses.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFilterOptions({ sellers, warehouses });
      setFilters((prev) => ({
        ...prev,
        warehouseId: inventoryFilter.warehouseId || _warehouses[0]?.id,
        sellerId: inventoryFilter.sellerId || _sellers[0]?.id,
      }));
    },
  });

  const [openExportDataForm, setOpenExportDataForm] = useState(false);
  const handleClickOpenExportDataForm = () => {
    setOpenExportDataForm(true);
  };
  const handleCloseExportDataForm = () => {
    setOpenExportDataForm(false);
  };

  const report = {
    hasReport: true,
    reportProperties: {
      mutation: GENERATE_PRODUCTS_AT_WAREHOUSE_REPORT,
      type: 'products_at_warehouse',
      dialogFunctions: {
        open: openExportDataForm,
        setOpen: setOpenExportDataForm,
        handleClickOpen: handleClickOpenExportDataForm,
        handleClose: handleCloseExportDataForm,
      },
      filterOptions,
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: <HeaderListData>Producto</HeaderListData>,
        accessor: 'product',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value: { sku, name } }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{name}</Typography>
            <Typography variant="caption">sku: {sku}</Typography>
          </Box>
        ),
      },
      {
        Header: <HeaderListData>Cantidad</HeaderListData>,
        accessor: 'quantityAvailable',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <ChipNumber label={value} />,
      },
    ],
    [],
  );
  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({
      ...prev,
      globalFilter,
    }));
  };
  if (loading)
    return (
      <>
        <PageTitle>Inventario en tienda</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <>
      <PageTitle
        handleClick={() => {
          setRun(true);
        }}
      >
        Inventario en tienda
      </PageTitle>
      <TourGuide
        steps={TOUR_STEPS}
        run={run}
        setRun={setRun}
        finishTour={showTourGuideListInventoryLocalStorage}
      />
      <ListServerData
        report={report}
        query={GET_INVENTORY}
        filters={{
          components: (
            <InventoryFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
              warehousesOptions={filterOptions.warehouses}
            />
          ),
          helperTextSearchBox: 'Buscar por producto, sku o cantidad',
        }}
        queryVariables={{ filters }}
        dataKey="getProductsAtWarehouses"
        handleGlobalFilter={handleGlobalFilter}
        initGlobalFilter={inventoryFilter.globalFilter}
        columns={columns}
        filterOptions={filterOptions}
      />
    </>
  );
}

export default ListInventory;
