import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

function SimpleMenu({ anchorEl, setAnchorEl, options, renderButton }) {
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const button = renderButton({ onClick: handleClick });

  return (
    <>
      {button}
      {!!options.length && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options.map((option) => (
            <Tooltip
              key={`menu-item-tooltip-${option.id}`}
              title={option.href ? '' : option.missingMessage}
            >
              <span key={`menu-item-tooltip-span-${option.id}`}>
                <MenuItem
                  component="a"
                  href={option.href}
                  key={`menu-item-simple-menu-${option.label}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={!option.href}
                >
                  {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                  <ListItemText>{option.label}</ListItemText>
                </MenuItem>
              </span>
            </Tooltip>
          ))}
        </Menu>
      )}
    </>
  );
}
SimpleMenu.propTypes = {
  renderButton: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape({}),
  setAnchorEl: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      href: PropTypes.string,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      missingMessage: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

SimpleMenu.defaultProps = {
  anchorEl: null,
};
export default SimpleMenu;
