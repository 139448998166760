import { gql } from '@apollo/client';

const GENERATE_ORDERS_REPORT = gql`
  mutation generateOrdersReport(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: OrderFilters!
    $receiver: String!
    $filename: String
    $userPrompt: String!
  ) {
    generateOrdersReport(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
      receiver: $receiver
      filename: $filename
      userPrompt: $userPrompt
    ) {
      entries {
        eventId
      }
      failedEvents
    }
  }
`;
const GENERATE_PRODUCTS_AT_WAREHOUSE_REPORT = gql`
  mutation generateProductsAtWarehouseReport(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: ProductAtWarehouseFilters!
    $receiver: String!
    $filename: String
    $userPrompt: String!
  ) {
    generateProductsAtWarehouseReport(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
      receiver: $receiver
      filename: $filename
      userPrompt: $userPrompt
    ) {
      entries {
        eventId
      }
      failedEvents
    }
  }
`;
const GENERATE_PRODUCTS_REPORT = gql`
  mutation generateProductsReport(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: ProductFilters!
    $receiver: String!
    $filename: String
    $userPrompt: String!
  ) {
    generateProductsReport(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
      receiver: $receiver
      filename: $filename
      userPrompt: $userPrompt
    ) {
      entries {
        eventId
      }
      failedEvents
    }
  }
`;
export {
  GENERATE_ORDERS_REPORT,
  GENERATE_PRODUCTS_AT_WAREHOUSE_REPORT,
  GENERATE_PRODUCTS_REPORT,
};
