import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';

function ChipNumber({ label }) {
  return (
    <Chip
      label={label}
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      })}
    />
  );
}

ChipNumber.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ChipNumber;
