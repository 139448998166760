import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function HeaderListData({ children }) {
  return (
    <Typography
      variant="subtitle1"
      color={grey[700]}
      sx={{ fontSize: '0.8rem' }}
    >
      {children}
    </Typography>
  );
}

HeaderListData.propTypes = {
  children: PropTypes.node.isRequired,
};
export default HeaderListData;
