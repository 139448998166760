import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function FileTemplate({ filePath, title }) {
  return (
    <Paper
      sx={(theme) => ({
        backgroundColor: theme.palette.grey.A200,
        padding: 2,
      })}
      elevation={0}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', marginBottom: 1, alignItems: 'center' }}>
            <AttachFileIcon fontSize="small" />
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
          <Typography variant="caption">
            Puedes descargar este archivo y usarlo de template
          </Typography>
        </Box>
        <Button
          variant="outlined"
          href={filePath}
          rel="noopener noreferrer"
          endIcon={<FileDownloadOutlinedIcon />}
        >
          Descargar
        </Button>
      </Box>
    </Paper>
  );
}
FileTemplate.propTypes = {
  filePath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
export default FileTemplate;
