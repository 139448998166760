import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

const BOX_PROPS = {
  display: 'flex',
  width: '100%',
  gap: 15,
  alignItems: 'center',
  marginBottom: 30,
};

const FilterBox = styled(Box)(() => BOX_PROPS);

export default FilterBox;
