import { useEffect, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { restClient } from '../../../../../../apollo/client';
import GoogleMapsAutocomplete from '../../../../../../components/google-maps/GoogleMapsAutocomplete';
import RadioButtonsGroup from '../../../../../../components/radio-buttons/RadioButtonsGroup';
import FormTextField from '../../../../../../components/text-fields/FormTextField';
import GET_CHECKOUT_RATES from '../../../../../../gql/checkout';
import { CURRENCY_FORMATTER } from '../../../../../../lib/currency';
import DELIVERY_METHODS, {
  DELIVERY_METHODS_LABEL_MAPPING,
} from '../../../../../../lib/deliveryMethods';
import { REQUIRED_FIELD } from '../../../../../../lib/form';
import ORDER_BUSINESS_TYPES from '../../../../../../lib/orderBusinessTypes';
import { CURRENCIES_SALES_TAX_MAPPER } from '../../../../../../lib/taxes';
import ManualShippingValue from './ManualShippingValue';

const { REACT_APP_VOLANS_SELLER_CONFIG_ID } = process.env;

function NewOrderCheckout({ register, control, setValue }) {
  const watchWarehouseId = useWatch({
    name: 'warehouseId',
    control,
  });
  const watchSellerId = useWatch({
    name: 'sellerId',
    control,
  });
  const watchShippingAddressLine1 = useWatch({
    name: 'shippingAddressLine1',
    control,
  });
  const watchDeliveryMethod = useWatch({
    name: 'deliveryMethod',
    control,
  });
  const watchOrderCurrency = useWatch({
    name: 'orderCurrency',
    control,
  });
  const watchCheckoutRate = useWatch({
    name: 'checkoutRate',
    control,
  });
  const watchBusinessType = useWatch({
    name: 'businessType',
    control,
  });
  const isB2BDelivery = useMemo(
    () =>
      watchBusinessType === ORDER_BUSINESS_TYPES.B2B &&
      watchDeliveryMethod === DELIVERY_METHODS.DELIVERY,
    [watchDeliveryMethod, watchBusinessType],
  );
  const isB2CDelivery = useMemo(
    () =>
      watchBusinessType === ORDER_BUSINESS_TYPES.B2C &&
      watchDeliveryMethod === DELIVERY_METHODS.DELIVERY,
    [watchDeliveryMethod, watchBusinessType],
  );
  const shippingRate = useMemo(() => {
    const { price, serviceName, currency } = Object.fromEntries(
      new URLSearchParams(watchCheckoutRate),
    );
    return { price, serviceName, currency };
  }, [watchCheckoutRate]);

  const items = useWatch({ name: 'items', control });
  const checkoutDisabled =
    !watchWarehouseId || !watchSellerId || !items?.length;

  const [getRates, { data, error, loading }] = useLazyQuery(
    GET_CHECKOUT_RATES,
    {
      client: restClient,
    },
  );

  const rates = useMemo(() => {
    if (isB2BDelivery) {
      return [];
    }
    return data?.checkoutRates?.rates ?? [];
  }, [data, isB2BDelivery]);

  const onChangeBusinessType = () => {
    setValue('shippingMethod', '');
    setValue('deliveryMethod', '');
    setValue('shippingRateType', '');
  };

  useEffect(() => {
    onChangeBusinessType();
  }, [watchBusinessType]);
  useEffect(() => {
    if (!checkoutDisabled && watchShippingAddressLine1 && isB2CDelivery) {
      getRates({
        variables: {
          input: {
            sellerConfigId: REACT_APP_VOLANS_SELLER_CONFIG_ID,
            shippingAddress: watchShippingAddressLine1,
            items,
            warehouseId: watchWarehouseId,
          },
        },
      });
    }
    setValue('checkoutRate', '');
  }, [
    watchShippingAddressLine1,
    watchSellerId,
    items,
    watchWarehouseId,
    isB2CDelivery,
  ]);

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="subtitle1">5. Despacho</Typography>
      </Box>
      <RadioButtonsGroup
        label="Selecciona el tipo de entrega"
        options={Object.keys(DELIVERY_METHODS).map((deliveryMethod) => ({
          label: (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>
                {DELIVERY_METHODS_LABEL_MAPPING[deliveryMethod]}
              </Typography>
            </Box>
          ),
          value: deliveryMethod,
        }))}
        control={control}
        fieldName="deliveryMethod"
      />
      {watchDeliveryMethod === DELIVERY_METHODS.DELIVERY && (
        <>
          <GoogleMapsAutocomplete
            fieldName="shippingAddressLine1"
            setValue={setValue}
            disabled={checkoutDisabled}
            control={control}
            tooltipTitle={
              checkoutDisabled
                ? 'Debes completar los campos anteriores para ingresar la dirección'
                : ''
            }
          />
          <FormTextField
            fieldName="shippingAddressLine2"
            control={control}
            label="Complemento, ejemplo: Depto 13A"
            sx={{ width: '100%' }}
            register={() => register('shippingAddressLine2')}
            disabled={checkoutDisabled}
          />
        </>
      )}
      {isB2BDelivery && (
        <FormTextField
          fieldName="manualShippingValue"
          control={control}
          register={() =>
            register('manualShippingValue', { required: REQUIRED_FIELD })
          }
          helperText="Tarifa personalizada"
          placeholder="Ingresa la tarifa de envío"
          label="Ingresa la tarifa de envío"
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
        />
      )}
      {isB2CDelivery && (
        <>
          {rates.length > 0 && (
            <>
              <RadioButtonsGroup
                label="Selecciona el método de envío"
                options={rates.map(({ __typename, ...rate }) => ({
                  label: (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography>{rate.serviceName}</Typography>
                      <Typography variant="caption">
                        {CURRENCY_FORMATTER[rate.currency].format(rate.price)}
                      </Typography>
                    </Box>
                  ),
                  value: new URLSearchParams(rate).toString(),
                }))}
                control={control}
                fieldName="checkoutRate"
              />
              {shippingRate.currency && isB2CDelivery && (
                <ManualShippingValue
                  control={control}
                  shippingRate={shippingRate}
                  register={register}
                />
              )}
            </>
          )}
          {loading && <LinearProgress color="secondary" />}
          {(!rates.length || error) &&
            !loading &&
            watchShippingAddressLine1 && (
              <Typography>
                No hemos encontrado tarifas de envío disponibles.
              </Typography>
            )}
          {!watchShippingAddressLine1 && (
            <Typography variant="caption">
              * Con la dirección de despacho podemos calcular las tarifas de
              envío.
            </Typography>
          )}
        </>
      )}
      {watchDeliveryMethod === DELIVERY_METHODS.DELIVERY && (
        <Typography variant="caption">
          * Recuerda que las tarifas de envío tienen el
          {` ${CURRENCIES_SALES_TAX_MAPPER[watchOrderCurrency]} `}
          incluido
        </Typography>
      )}
    </Box>
  );
}

NewOrderCheckout.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
};

export default NewOrderCheckout;
