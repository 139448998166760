const keyMirror = require('keymirror');

const DELIVERY_METHODS = keyMirror({
  PICK_UP: null,
  DELIVERY: null,
});

const DELIVERY_METHODS_LABEL_MAPPING = {
  [DELIVERY_METHODS.PICK_UP]: 'Retiro en bodega',
  [DELIVERY_METHODS.DELIVERY]: 'Delivery',
};

export { DELIVERY_METHODS_LABEL_MAPPING };
export default DELIVERY_METHODS;
