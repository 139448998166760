const STORAGE_OPTIONS = [
  {
    value: 'DRY',
    label: 'Seco',
  },
  {
    value: 'REFRIGERATED',
    label: 'Refrigerado',
  },
  {
    value: 'FROZEN',
    label: 'Congelado',
  },
];
export default STORAGE_OPTIONS;
