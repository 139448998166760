import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { formatCurrencyValue } from 'lib/currency';
import HeaderListData from 'views/commons/HeaderListData';

const columns = ({ removeOrder }) => [
  {
    Header: <HeaderListData fontSize="0.8rem">#Orden</HeaderListData>,
    accessor: 'orderNumber',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Tipo</HeaderListData>,
    accessor: 'businessType',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Envío</HeaderListData>,
    accessor: 'shippingMethod',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: (
      <HeaderListData fontSize="0.8rem" sx={{ whiteSpace: 'nowrap' }}>
        Precio envío
      </HeaderListData>
    ),
    accessor: 'shippingValue',
    align: 'right',
    Cell: ({
      /* eslint-disable-next-line react/prop-types */
      value,
      /* eslint-disable-next-line react/prop-types */
      row: {
        /* eslint-disable-next-line react/prop-types */
        original: { orderCurrency },
      },
    }) => (
      <Typography>
        {formatCurrencyValue({
          currency: orderCurrency,
          value,
        })}
      </Typography>
    ),
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Cliente</HeaderListData>,
    accessor: 'customer',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (
      <Typography>
        {/* eslint-disable-next-line react/prop-types */}
        {Object.values(value)
          .filter((nameComponent) => nameComponent)
          .join(' ')}
      </Typography>
    ),
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Dirección</HeaderListData>,
    accessor: 'shippingAddress',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (
      <Typography>
        {/* eslint-disable-next-line react/prop-types */}
        {Object.values(value)
          .filter((nameComponent) => nameComponent)
          .join(', ')}
      </Typography>
    ),
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Comentario</HeaderListData>,
    accessor: 'comment',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: '',
    id: 'delete',
    accessor: 'orderNumber',
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => (
      <Box
        key={`delete-item${value}-box`}
        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
      >
        <IconButton
          onClick={() => {
            removeOrder(value);
          }}
        >
          <Tooltip title="Eliminar orden">
            <ClearIcon />
          </Tooltip>
        </IconButton>
      </Box>
    ),
  },
];

export default columns;
