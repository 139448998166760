import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import LinkButton from './LinkButton';

const SIDEBAR_LIST = [
  { text: 'Órdenes', icon: <ListAltOutlinedIcon />, to: '/orders' },
  { text: 'Productos', icon: <CategoryOutlinedIcon />, to: '/catalogs' },
  { text: 'Inventario', icon: <StorefrontOutlinedIcon />, to: '/inventory' },
  {
    text: 'Abastecimientos',
    icon: <ArchiveOutlinedIcon />,
    to: '/replenishments',
  },
];

function SidebarContent({ open }) {
  return (
    <>
      <Divider />
      <List>
        {SIDEBAR_LIST.map(({ text, icon, to }) => (
          <Tooltip key={text} arrow placement="right" title={open ? '' : text}>
            <span>
              <LinkButton text={text} icon={icon} to={to} open={open} />
            </span>
          </Tooltip>
        ))}
      </List>
    </>
  );
}

SidebarContent.propTypes = {
  open: PropTypes.bool.isRequired,
};
export default SidebarContent;
