/* eslint-disable no-plusplus */

import { useQuery } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { es } from 'date-fns/locale';

import Layout from './components/layout/Layout';
import IS_LOGGED_IN from './gql/auth';
import Routes from './routes/Routes';
import theme from './styles/theme';

function App() {
  const { data, loading } = useQuery(IS_LOGGED_IN);
  const { isLoggedIn } = data || {};
  if (loading)
    return (
      <ThemeProvider theme={theme}>
        <LinearProgress color="secondary" />
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
        {isLoggedIn ? (
          <Layout>
            <Routes />
          </Layout>
        ) : (
          <Routes />
        )}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
