import { useState, useEffect, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useAsyncDebounce } from 'react-table';

const MILI_SECONDS_DEBOUNCED = 1000;
const OFFSET_FIRST_PAGE = 0;
const LIMIT = 10;

function usePaginatedQuery({ query, sort, filters, validateFilters }) {
  const queryFilters = useMemo(() => filters, [filters]);
  const [limit, setLimit] = useState(LIMIT);
  const {
    definitions: {
      0: {
        name: { value: queryName },
      },
    },
  } = query;

  const [getData, { loading, data, error, fetchMore }] = useLazyQuery(query, {
    notifyOnNetworkStatusChange: true,
  });
  const fetchData = ({ variables }) => {
    if (validateFilters(queryFilters)) {
      getData({
        variables,
      });
    }
  };
  const onFetchDataDebounced = useAsyncDebounce(
    fetchData,
    MILI_SECONDS_DEBOUNCED,
  );

  useEffect(() => {
    const variables = {
      offset: OFFSET_FIRST_PAGE,
      limit,
      sort,
      filters: queryFilters,
    };
    onFetchDataDebounced({ variables });
  }, [limit]);
  useEffect(() => {
    const variables = {
      offset: OFFSET_FIRST_PAGE,
      limit: LIMIT,
      sort,
      filters: queryFilters,
    };
    onFetchDataDebounced({ variables });
  }, [queryFilters]);

  const onLoadMore = () => {
    const currentLength = data[queryName].nodes.length;
    const totalCount = data[queryName].total;
    if (totalCount > currentLength) {
      fetchMore({
        variables: {
          offset: currentLength,
          limit: LIMIT,
          filters: queryFilters,
        },
      }).then((fetchMoreResult) => {
        setLimit(currentLength + fetchMoreResult.data[queryName].nodes.length);
      });
    }
  };
  return {
    data: data ? data[queryName].nodes : data,
    loading,
    error,
    onLoadMore,
  };
}
export default usePaginatedQuery;
