import { useState, useEffect, useMemo } from 'react';

import { useReactiveVar, useQuery } from '@apollo/client';

import { productFilterVar } from 'apollo/reactiveVariables';
import PageTitle from 'components/layout/PageTitle';
import SkeletonListData from 'components/skeletons/ListData';
import Tag from 'components/Tag';
import { GET_PRODUCTS } from 'gql/catalogs';
import GET_FILTERS from 'gql/filters';
import ProductFilters from 'views/catalogs/products/list/ProductFilters';
import HeaderListData from 'views/commons/HeaderListData';
import ListServerData from 'views/commons/ListServerData';

import CellText from './components/CellText';
import PackDetails from './components/PackDetails';

function PackTable() {
  const productFilter = useReactiveVar(productFilterVar);
  const [filters, setFilters] = useState(productFilter);

  const [filterOptions, setFilterOptions] = useState({
    sellers: [],
  });

  const { loading } = useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        catalogFilterOptions: { sellers: _sellers },
      },
    }) => {
      const sellers = _sellers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFilterOptions({ sellers });
      setFilters((prev) => ({
        ...prev,
        sellerId: productFilter.sellerId || _sellers[0]?.id,
      }));
    },
  });

  useEffect(() => {
    productFilterVar(filters);
  }, [filters]);

  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({ ...prev, globalFilter }));
  };

  const columns = useMemo(
    () => [
      {
        Header: <HeaderListData>Nombre</HeaderListData>,
        accessor: 'name',
        Cell: CellText,
      },
      {
        Header: <HeaderListData>SKU</HeaderListData>,
        accessor: 'sku',
        Cell: CellText,
      },
      {
        Header: <HeaderListData>Tipo pack</HeaderListData>,
        accessor: 'packType',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Tag label={value} />,
      },

      {
        Header: <HeaderListData>Cantidad de componentes</HeaderListData>,
        accessor: 'componentsQuantity',
        Cell: CellText,
      },
    ],
    [],
  );

  const renderSubComponent = (row) => {
    const {
      original: { id: productId, seller },
    } = row;

    return <PackDetails sellerId={seller.id} productId={productId} />;
  };

  if (loading)
    return (
      <>
        <PageTitle>Packs registrados</PageTitle>
        <SkeletonListData />
      </>
    );

  return (
    <>
      <PageTitle>Packs registrados</PageTitle>
      <ListServerData
        query={GET_PRODUCTS}
        dataKey="getProducts"
        renderSubComponent={renderSubComponent}
        filters={{
          components: (
            <ProductFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
            />
          ),
          helperTextSearchBox: 'Buscar por producto o sku',
        }}
        initGlobalFilter={productFilter.globalFilter}
        queryVariables={{
          filters: {
            hasComponents: true,
            ...filters,
          },
        }}
        handleGlobalFilter={handleGlobalFilter}
        columns={columns}
      />
    </>
  );
}

export default PackTable;
