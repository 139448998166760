import { from, HttpLink, ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';

import loginRedirect, {
  AUTHENTICATION_ERROR_MESSAGE,
  AUTHORIZATION_ERROR_MESSAGE,
} from '../auth/utils';
import cache from './cache';

const { REACT_APP_PYXIS_API_URL, REACT_APP_VOLANS_API_URL } = process.env;
const IS_LOGGED_IN = 'isLoggedIn';
const httpLink = new HttpLink({ uri: REACT_APP_PYXIS_API_URL });
const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem('access_token');
  const tokenType = localStorage.getItem('token_type');
  return {
    headers: {
      ...headers,
      authorization: `${tokenType} ${accessToken}`,
    },
  };
});

const errorLink = onError((obj) => {
  const { graphQLErrors } = obj;
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      switch (true) {
        case message.startsWith(AUTHENTICATION_ERROR_MESSAGE):
          if (!path.includes(IS_LOGGED_IN)) loginRedirect();
          break;
        case message.startsWith(AUTHORIZATION_ERROR_MESSAGE):
          window.location.replace('/auth_error');
          break;
        default:
        //
      }
    });
  }
});

const apolloLink = from([authLink, errorLink, httpLink]);
const client = new ApolloClient({
  link: apolloLink,
  cache,
});

const httpRestLink = new RestLink({
  uri: REACT_APP_VOLANS_API_URL,
});

const restClient = new ApolloClient({
  link: httpRestLink,
  cache,
});

export { restClient };
export default client;
