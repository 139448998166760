import { object, array, string, number, setLocale } from 'yup';

import { CURRENCIES } from 'lib/currency';
import ORDER_BUSINESS_TYPES from 'lib/orderBusinessTypes';
import { SHIPPING_METHODS } from 'lib/shippingMethods';

setLocale({
  mixed: {
    default: 'Campo inválido',
    required: ({ path }) => {
      const index = Number(path.match(/\[(.*?)\]/)[1]);
      return { index, path, message: 'Campo obligatorio' };
    },
    oneOf: ({ value, values, path }) => {
      const index = Number(path.match(/\[(.*?)\]/)[1]);
      return {
        index,
        path,
        message: `${value} no pertenece a las opciones permitidas: ${values}`,
      };
    },
  },
  number: {
    min: ({ value, min, path }) => {
      const index = Number(path.match(/\[(.*?)\]/)[1]);
      return {
        index,
        path,
        message: `${value} no es mayor o igual a ${min}`,
      };
    },
  },
});

const ordersSchema = array().of(
  object({
    orderNumber: string().required(),
    orderCurrency: string().oneOf([CURRENCIES.CLP, CURRENCIES.PEN]).required(),
    shippingValue: number().min(0).required(),
    shippingMethod: string().when('businessType', {
      is: ORDER_BUSINESS_TYPES.B2C,
      then: (schema) =>
        schema
          .oneOf([
            SHIPPING_METHODS.PICK_UP,
            SHIPPING_METHODS.SAME_DAY,
            SHIPPING_METHODS.EXPRESS,
            SHIPPING_METHODS.STANDARD,
          ])
          .required(),
    }),
    shippingAddress: object({
      addressLine1: string().required(),
      addressLine2: string().nullable(),
    }),
    customer: object({
      firstName: string().required(),
      lastName: string().required(),
      email: string().required(),
      phone: string().nullable(),
    }),
    comment: string(),
    businessType: string()
      .oneOf([ORDER_BUSINESS_TYPES.B2B, ORDER_BUSINESS_TYPES.B2C])
      .required(),
    items: array().of(
      object({
        sku: string().required(),
        quantity: number().required().min(1),
        unitPrice: number().required().min(1),
      }),
    ),
  }),
);
export default ordersSchema;
