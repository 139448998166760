import DELIVERY_METHODS from '../../../../../lib/deliveryMethods';
import ORDER_BUSINESS_TYPES from '../../../../../lib/orderBusinessTypes';
import { SHIPPING_METHODS } from '../../../../../lib/shippingMethods';

const DEFAULT_SHIPPING_VALUE = 0;
const MANUAL_SHIPPING_RATE_TYPE = 'manualRate';

function translateOrder(data) {
  const {
    orderNumber,
    warehouseId,
    sellerId,
    firstName,
    lastName,
    phone,
    email,
    items,
    comment,
    orderCurrency,
    shippingAddressLine1,
    shippingAddressLine2,
    checkoutRate,
    shippingRateType,
    manualShippingValue,
    warehouseAddress,
    deliveryMethod,
    attachments,
    businessType,
  } = data;
  const {
    shippingMethod: shippingMethodRate,
    carrierCode: carrierCodeRate,
    price,
  } = Object.fromEntries(new URLSearchParams(checkoutRate)); // checkoutRates come from Volans
  const shippingMethod =
    businessType === ORDER_BUSINESS_TYPES.B2B
      ? SHIPPING_METHODS.MANUAL
      : shippingMethodRate;
  const shippingValue =
    shippingRateType === MANUAL_SHIPPING_RATE_TYPE ||
    businessType === ORDER_BUSINESS_TYPES.B2B
      ? manualShippingValue
      : price;
  const carrierCode = carrierCodeRate === 'null' ? null : carrierCodeRate;
  const attachedFiles = attachments
    .map(({ key, title }) => ({ key, title }))
    .filter(
      (attachedFile) =>
        !Object.values(attachedFile).some((attachedValue) => !attachedValue),
    );
  const order = {
    orderNumber,
    warehouseId,
    sellerId,
    deliveryMethod,
    shippingMethod:
      deliveryMethod === DELIVERY_METHODS.PICK_UP
        ? SHIPPING_METHODS.PICK_UP
        : shippingMethod,
    shippingAddress: {
      addressLine1:
        deliveryMethod === DELIVERY_METHODS.PICK_UP
          ? warehouseAddress
          : shippingAddressLine1,
      addressLine2:
        deliveryMethod === DELIVERY_METHODS.PICK_UP
          ? null
          : shippingAddressLine2,
    },
    deliveryData: {
      carrierCode,
    },
    customer: {
      firstName,
      lastName,
      phone,
      email,
    },
    items: items.map(({ id, sku, name, quantity, unitPrice }) => ({
      productId: id,
      sku,
      description: name,
      quantity: Number(quantity),
      totalValue: Number(unitPrice) * Number(quantity),
      unitPrice: Number(unitPrice),
    })),
    totalValue:
      items.reduce(
        (acc, { quantity, unitPrice }) =>
          acc + Number(unitPrice) * Number(quantity),
        0,
      ) + Number(shippingValue),
    shippingValue: shippingValue
      ? Number(shippingValue)
      : DEFAULT_SHIPPING_VALUE,
    orderCurrency,
    comment,
    ...(attachedFiles.length && { attachedFiles }),
    businessType,
  };
  return order;
}
export default translateOrder;
