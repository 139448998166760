import { read, utils } from 'xlsx';

async function readExcel(files, validateWorksheet, onComplete) {
  const file = files[0];
  const f = await file.arrayBuffer();
  const wb = read(f); // parse the array buffer
  const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
  const isValid = validateWorksheet(ws);
  if (!isValid) {
    return onComplete({ success: false });
  }
  const data = utils.sheet_to_json(ws); // generate objects
  return onComplete({ data, success: true });
}

export default readExcel;
