import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

function EditableCell({
  initialValue,
  rowIndex,
  editableRowIndex,
  cellRender,
  cellEditingRender,
}) {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // If the initialValue is changed externall, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return rowIndex === editableRowIndex
    ? cellEditingRender()
    : cellRender(value);
}

EditableCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
  rowIndex: PropTypes.number.isRequired,
  editableRowIndex: PropTypes.number,
  cellRender: PropTypes.func.isRequired,
  cellEditingRender: PropTypes.func.isRequired,
};
EditableCell.defaultProps = {
  initialValue: undefined,
  editableRowIndex: -1,
};

export default EditableCell;
