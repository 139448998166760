import { format, isMatch } from 'date-fns';

const DATE_FORMAT = 'd/MM/yyyy h:mm a';

function dateParser(date) {
  if (isMatch(date, DATE_FORMAT) || !date) {
    return date;
  }
  return format(new Date(date), DATE_FORMAT);
}
export default dateParser;
