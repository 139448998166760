import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import PropTypes from 'prop-types';

import SidebarContent from './SideBarContent';
import SidebarHeader from './SideBarHeader';

const NOT_FORWARDED_PROPS = ['open', 'openedMixin', 'closedMixin'];

const StyledSidebar = styled(Drawer, {
  shouldForwardProp: (prop) => NOT_FORWARDED_PROPS.indexOf(prop) === -1,
})((_props) => {
  const { theme, open, openedMixin, closedMixin } = _props;
  return {
    width: theme.drawer.width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  };
});

function Sidebar({ openedMixin, closedMixin, handleDrawerClose, open }) {
  const theme = useTheme();
  return (
    <StyledSidebar
      open={open}
      openedMixin={openedMixin}
      closedMixin={closedMixin}
      variant="permanent"
    >
      <SidebarHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </SidebarHeader>
      <SidebarContent open={open} />
    </StyledSidebar>
  );
}
Sidebar.propTypes = {
  openedMixin: PropTypes.func.isRequired,
  closedMixin: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Sidebar;
