import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import MenuProps from './styles';

function SimpleSelect({
  id,
  selectLabel,
  options,
  helperText,
  variant,
  controlled: { controlledValue, setControlledValue },
  eventName,
  size,
  sx,
}) {
  const hasOneOption = options.length === 1;
  const currentValue = options.length ? controlledValue : '';

  const handleChange = (event) => {
    if (eventName) {
      setControlledValue((prev) => ({
        ...prev,
        [eventName]: event.target.value,
      }));
      return;
    }
    setControlledValue(event.target.value);
  };

  return (
    <TextField
      id={id}
      select
      color="secondary"
      label={selectLabel}
      name={eventName}
      value={currentValue}
      onChange={handleChange}
      helperText={helperText}
      variant={variant}
      size={size}
      sx={sx}
      disabled={hasOneOption}
      SelectProps={{ MenuProps }}
    >
      {options.map(({ value: optionValue, label: optionLabel }) => (
        <MenuItem key={`${optionValue}`} value={optionValue}>
          {optionLabel}
        </MenuItem>
      ))}
    </TextField>
  );
}

SimpleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  selectLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  controlled: PropTypes.shape({
    controlledValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    setControlledValue: PropTypes.func,
  }),
  eventName: PropTypes.string,
  size: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

SimpleSelect.defaultProps = {
  selectLabel: '',
  helperText: '',
  variant: 'standard',
  controlled: { controlledValue: false, setControlledValue: () => {} },
  eventName: '',
  size: 'small',
  sx: {},
};

export default SimpleSelect;
