const UNKNOWN = 'UNKNOWN';
function customerParser(customer) {
  const customerParsed = Object.keys(customer).reduce(
    (acc, current) => ({
      ...acc,
      [current]: customer[current] === UNKNOWN ? null : customer[current],
    }),
    {},
  );
  return customerParsed;
}
function hasCustomerInfo(customer) {
  const isMissingInfo = Object.keys(customer)
    .filter((key) => key !== '__typename')
    .reduce((acc, current) => !customer[current] && acc, true);
  return !isMissingInfo;
}
export { hasCustomerInfo };
export default customerParser;
