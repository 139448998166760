const TEMPLATE_EXCEL_HEADERS = {
  A1: 'orderNumber',
  B1: 'businessType',
  C1: 'currency',
  D1: 'shippingMethod',
  E1: 'shippingValue',
  F1: 'lineItemQuantity',
  G1: 'lineItemPrice',
  H1: 'lineItemSku',
  I1: 'shippingAddressLine1',
  J1: 'shippingAddressLine2',
  K1: 'customerFirstName',
  L1: 'customerLastName',
  M1: 'customerEmail',
  N1: 'customerPhone',
  O1: 'comment',
};
export default TEMPLATE_EXCEL_HEADERS;
