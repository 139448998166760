import ORDER_BUSINESS_TYPES from 'lib/orderBusinessTypes';

const DEFAULT_SHIPPING_VALUE = 0;

function translateOrders({ tableData, watchSellerId, watchWarehouseId }) {
  const fileOrders = tableData.reduce((acc, current) => {
    if (!current.orderNumber) return acc;
    return {
      ...acc,
      [current.orderNumber]: {
        orderNumber: current.orderNumber?.toString(),
        sellerId: watchSellerId,
        warehouseId: watchWarehouseId,
        orderCurrency: current.currency,
        shippingValue: current.shippingValue || DEFAULT_SHIPPING_VALUE,
        shippingMethod:
          current.businessType === ORDER_BUSINESS_TYPES.B2C
            ? current.shippingMethod
            : undefined,
        shippingAddress: {
          addressLine1: current.shippingAddressLine1,
          addressLine2: current.shippingAddressLine2,
        },
        customer: {
          firstName: current.customerFirstName,
          lastName: current.customerLastName,
          email: current.customerEmail,
          phone: current.customerPhone?.toString(),
        },
        comment: current.comment,
        businessType: current.businessType,
        items: [
          ...(acc[current.orderNumber]?.items || []),
          {
            sku: current.lineItemSku?.toString(),
            quantity: current.lineItemQuantity,
            unitPrice: current.lineItemPrice,
          },
        ],
      },
    };
  }, {});

  return Object.values(fileOrders);
}
export default translateOrders;
