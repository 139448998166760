import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function PageTitle({ children, handleClick }) {
  return (
    <Box id="pageTitle" sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
      <Typography variant="h5">{children}</Typography>
      {handleClick && (
        <Tooltip title="Ver tutorial aquí">
          <span>
            <IconButton onClick={handleClick} color="secondary">
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Box>
  );
}

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};
PageTitle.defaultProps = {
  handleClick: false,
};
export default PageTitle;
