import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Table from 'components/table/Table';
import Tag from 'components/Tag';
import { GET_PRODUCT } from 'gql/catalogs';
import HeaderListData from 'views/commons/HeaderListData';

import CellText from './CellText';

function PackDetails({ sellerId, productId }) {
  const { data, loading, error } = useQuery(GET_PRODUCT, {
    variables: {
      id: productId,
      sellerId,
    },
  });
  const columns = useMemo(
    () => [
      {
        Header: <HeaderListData>Nombre</HeaderListData>,
        accessor: 'name',
        Cell: CellText,
      },
      {
        Header: <HeaderListData>SKU</HeaderListData>,
        accessor: 'sku',
        Cell: CellText,
      },
      {
        Header: <HeaderListData>Tipo componente</HeaderListData>,
        accessor: 'componentType',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Tag label={value} />,
      },
      {
        Header: <HeaderListData>Cantidad</HeaderListData>,
        accessor: 'quantity',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const {
            // eslint-disable-next-line react/prop-types
            original: {
              // eslint-disable-next-line react/prop-types
              quantity,
              // eslint-disable-next-line react/prop-types
              maxComponentQuantity,
            },
          } = row;
          // eslint-disable-next-line react/prop-types
          return <CellText value={maxComponentQuantity || quantity} />;
        },
      },
      {
        Header: <HeaderListData>Tipo Almacenaje</HeaderListData>,
        id: 'storageType',
        accessor: 'storageType',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const {
            // eslint-disable-next-line react/prop-types
            original: {
              // eslint-disable-next-line react/prop-types
              product: { storageType },
            },
          } = row;
          // eslint-disable-next-line react/prop-types
          return (
            <Tag
              // eslint-disable-next-line react/prop-types
              label={storageType.label}
              // eslint-disable-next-line react/prop-types
              type={storageType.value}
            />
          );
        },
      },
      {
        Header: <HeaderListData>Dimensiones</HeaderListData>,
        accessor: 'volume',
        Cell: CellText,
      },
      {
        Header: <HeaderListData>Peso</HeaderListData>,
        accessor: 'weight',
        Cell: CellText,
      },
    ],
    [],
  );

  if (error) {
    return (
      <Typography>
        Ha ocurrido un error al buscar los detalles del pack. Por favor
        inténtalo nuevamente más tarde.
      </Typography>
    );
  }

  if (loading) {
    return <CircularProgress color="secondary" />;
  }
  return (
    <>
      <Typography variant="h6">Detalle del pack {data.product.name}</Typography>

      <Table
        data={data.product.components.map((component) => ({
          ...component,
          ...component.product,
        }))}
        columns={columns}
        pagination={false}
        sxPaper={{ boxShadow: 'none' }}
        hasSearchBox={false}
      />
    </>
  );
}

PackDetails.propTypes = {
  sellerId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default PackDetails;
