import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function Tag({ label, type }) {
  const theme = useTheme();
  const {
    palette: { tagColor },
  } = theme;

  return (
    <Box
      sx={{
        background: tagColor.backgroundColor[type],
        paddingY: 0.75,
        paddingX: 1.785,
        display: 'inline-flex',
        justifyContent: 'center',
        borderRadius: 1.5,
      }}
    >
      <Typography
        component="div"
        sx={{ color: tagColor.textColor[type], fontSize: '12px' }}
      >
        <Box sx={{ textTransform: 'uppercase' }}>{label}</Box>
      </Typography>
    </Box>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['REFRIGERATED', 'FROZEN', 'DRY', 'DEFAULT']),
};
Tag.defaultProps = {
  type: 'DEFAULT',
};

export default Tag;
