import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function TableBox({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 250,
        padding: 5,
        gap: 3,
      }}
    >
      {children}
    </Box>
  );
}
TableBox.propTypes = {
  children: PropTypes.node.isRequired,
};
export default TableBox;
