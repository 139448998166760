import { useState } from 'react';

import axios from 'axios';

function useUploadFile() {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
    completed: false,
  });

  const uploadFile = async (file, url) => {
    setStatus({ loading: true, error: false, completed: false });
    try {
      await axios.put(url, file, {
        headers: { 'Content-Type': file.type },
      });
      setStatus({ loading: false, error: false, completed: true });
    } catch {
      setStatus({ loading: false, error: true, completed: false });
    }
  };
  return [uploadFile, status];
}

export default useUploadFile;
