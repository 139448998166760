import { useMemo, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';

import Table from '../../components/table/Table';
import ArrayFormTextFieldCell from '../../components/text-fields/ArrayFormTextFieldCell';
import { CURRENCIES } from '../../lib/currency';

const DATE_FORMAT = 'dd/MM/yyyy';
const MIN_NUMBER_VALIDATION = 1;
const DATE_PICKER_WIDTH = 160;
const DEFAULT_WIDTH = 100;
const QUANTITY_WIDTH = 80;
const CURRENCY_WIDTH = 80;

function ReplenishmentTable({
  control,
  register,
  setValue,
  replenishmentData,
  remove,
}) {
  const columns = useMemo(
    () => [
      {
        Header: <Typography>Sku</Typography>,
        accessor: 'sku',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          const currentItemIndex = useMemo(
            () => replenishmentData.findIndex((item) => item.sku === value),
            [replenishmentData, value],
          );
          return (
            <ArrayFormTextFieldCell
              formItemHeaderName="sku"
              currentItemIndex={currentItemIndex}
              control={control}
              register={register}
              width={DEFAULT_WIDTH}
            />
          );
        },
      },
      {
        Header: <Typography>Cantidad</Typography>,
        accessor: 'quantity',
        // eslint-disable-next-line react/prop-types
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { sku },
          },
        }) => {
          const currentItemIndex = useMemo(
            () => replenishmentData.findIndex((item) => item.sku === sku),
            [replenishmentData, sku],
          );
          return (
            <ArrayFormTextFieldCell
              formItemHeaderName="quantity"
              currentItemIndex={currentItemIndex}
              control={control}
              register={register}
              inputType="number"
              valueAsNumber
              validation={{ min: MIN_NUMBER_VALIDATION }}
              width={QUANTITY_WIDTH}
            />
          );
        },
      },
      {
        Header: <Typography>Costo unitario</Typography>,
        accessor: 'unitCost',
        // eslint-disable-next-line react/prop-types
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { sku },
          },
        }) => {
          const currentItemIndex = useMemo(
            () => replenishmentData.findIndex((item) => item.sku === sku),
            [replenishmentData, sku],
          );
          return (
            <ArrayFormTextFieldCell
              formItemHeaderName="unitCost"
              currentItemIndex={currentItemIndex}
              control={control}
              register={register}
              inputType="number"
              valueAsNumber
              validation={{ min: MIN_NUMBER_VALIDATION }}
              required={false}
              width={DEFAULT_WIDTH}
            />
          );
        },
      },
      {
        Header: <Typography>Moneda</Typography>,
        accessor: 'costCurrency',
        // eslint-disable-next-line react/prop-types
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { sku, costCurrency },
          },
        }) => {
          const currentItemIndex = useMemo(
            () => replenishmentData.findIndex((item) => item.sku === sku),
            [replenishmentData, sku],
          );
          return (
            <ArrayFormTextFieldCell
              formItemHeaderName="costCurrency"
              currentItemIndex={currentItemIndex}
              control={control}
              register={register}
              required={false}
              defaultValue={costCurrency || ''}
              select={{
                select: true,
                options: Object.keys(CURRENCIES).map((currency) => ({
                  value: currency,
                  label: currency,
                })),
              }}
              width={CURRENCY_WIDTH}
            />
          );
        },
      },
      {
        Header: <Typography>Número de lote</Typography>,
        accessor: 'lotNumber',
        // eslint-disable-next-line react/prop-types
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { sku },
          },
        }) => {
          const currentItemIndex = useMemo(
            () => replenishmentData.findIndex((item) => item.sku === sku),
            [replenishmentData, sku],
          );
          return (
            <ArrayFormTextFieldCell
              formItemHeaderName="lotNumber"
              currentItemIndex={currentItemIndex}
              control={control}
              register={register}
              required={false}
              width={DEFAULT_WIDTH}
            />
          );
        },
      },
      {
        Header: <Typography>Fecha de expiración</Typography>,
        accessor: 'expirationDate',
        // eslint-disable-next-line react/prop-types
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { sku },
          },
        }) => {
          const currentItemIndex = useMemo(
            () => replenishmentData.findIndex((item) => item.sku === sku),
            [replenishmentData, sku],
          );

          const [date, setDate] = useState(value ?? null);
          return (
            <DatePicker
              inputFormat={DATE_FORMAT}
              value={date}
              onChange={(event) => {
                setDate(event.toString());
                setValue(
                  `items[${currentItemIndex}].expirationDate`,
                  event.toString(),
                );
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ width: DATE_PICKER_WIDTH }}
                  size="small"
                />
              )}
            />
          );
        },
      },
      {
        Header: '',
        id: 'delete',
        accessor: 'sku',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => {
          const currentItemIndex = useMemo(
            () => replenishmentData.findIndex((item) => item.sku === value),
            [replenishmentData, value],
          );
          return (
            <Box
              key={`delete-item${value}-box`}
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            >
              <IconButton
                onClick={() => {
                  remove(currentItemIndex);
                }}
              >
                <Tooltip title="Eliminar">
                  <ClearIcon />
                </Tooltip>
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [replenishmentData],
  );
  return (
    <Table
      data={replenishmentData}
      columns={columns}
      pagination={{
        hasPagination: false,
        customPageSize: replenishmentData.length,
      }}
      hasStickyHeader={false}
      hasSearchBox={false}
      paperBoxProps={{
        elevation: 0,
        sx: {
          borderRadius: '4px',
          border: '1px solid #EEEDED',
        },
      }}
    />
  );
}

ReplenishmentTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  replenishmentData: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      quantity: PropTypes.number,
      unitCost: PropTypes.number,
      costCurrency: PropTypes.string,
      lotNumber: PropTypes.string,
      expirationDate: PropTypes.instanceOf(Date),
    }),
  ).isRequired,
  remove: PropTypes.func.isRequired,
};

export default ReplenishmentTable;
