import { useMemo } from 'react';

import PropTypes from 'prop-types';

import SimpleSelect from '../../components/selects/SimpleSelect';

function ReplenishmentFilters({
  warehousesOptions,
  sellersOptions,
  setFilters,
  filters,
}) {
  const hasOneSeller = useMemo(
    () => sellersOptions.length === 1,
    [sellersOptions],
  );
  return (
    <>
      {!hasOneSeller && (
        <SimpleSelect
          id="filter-seller-select"
          variant="outlined"
          helperText="Filtrar por vendedor"
          selectLabel="Vendedor"
          options={sellersOptions}
          eventName="sellerId"
          controlled={{
            controlledValue: filters.sellerId,
            setControlledValue: setFilters,
          }}
          sx={{ width: 180 }}
        />
      )}
      <SimpleSelect
        id="filter-seller-select"
        variant="outlined"
        helperText="Filtrar por tienda"
        selectLabel="Tienda"
        options={warehousesOptions}
        eventName="warehouseId"
        controlled={{
          controlledValue: filters.warehouseId,
          setControlledValue: setFilters,
        }}
        sx={{ width: 180 }}
      />
    </>
  );
}
ReplenishmentFilters.propTypes = {
  filters: PropTypes.shape({
    sellerId: PropTypes.string,
    warehouseId: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  sellersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  warehousesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

ReplenishmentFilters.defaultProps = {
  sellersOptions: [],
  warehousesOptions: [],
};
export default ReplenishmentFilters;
