import PropTypes from 'prop-types';

import SimpleSelect from 'components/selects/SimpleSelect';

function InventoryFilters({
  filters,
  setFilters,
  warehousesOptions,
  sellersOptions,
}) {
  const hasOneSeller = sellersOptions.length === 1;
  return (
    <>
      <SimpleSelect
        id="filter-warehouse-select"
        variant="outlined"
        helperText="Filtrar por tienda"
        selectLabel="Tienda"
        options={warehousesOptions}
        eventName="warehouseId"
        controlled={{
          controlledValue: filters.warehouseId,
          setControlledValue: setFilters,
        }}
        sx={{ width: 180 }}
      />
      {!hasOneSeller && (
        <SimpleSelect
          id="filter-seller-select"
          variant="outlined"
          helperText="Filtrar por vendedor"
          selectLabel="Vendedor"
          options={sellersOptions}
          eventName="sellerId"
          controlled={{
            controlledValue: filters.sellerId,
            setControlledValue: setFilters,
          }}
          sx={{ width: 180 }}
        />
      )}
    </>
  );
}
InventoryFilters.propTypes = {
  filters: PropTypes.shape({
    warehouseId: PropTypes.string,
    sellerId: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  warehousesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  sellersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

InventoryFilters.defaultProps = {
  warehousesOptions: [],
  sellersOptions: [],
};
export default InventoryFilters;
