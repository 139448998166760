import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import FormTextField from '../../../../../components/text-fields/FormTextField';
import { REQUIRED_FIELD } from '../../../../../lib/form';

function NewOrderCustomer({ register, control }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography variant="subtitle1">4. Cliente</Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormTextField
          fieldName="firstName"
          label="Nombre"
          control={control}
          register={() => register('firstName', { required: REQUIRED_FIELD })}
        />
        <FormTextField
          fieldName="lastName"
          label="Apellido"
          control={control}
          register={() => register('lastName', { required: REQUIRED_FIELD })}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormTextField
          fieldName="email"
          label="Correo"
          control={control}
          register={() => register('email', { required: REQUIRED_FIELD })}
        />
        <FormTextField
          fieldName="phone"
          label="Celular"
          control={control}
          register={() => register('phone', { required: REQUIRED_FIELD })}
        />
      </Box>
    </Box>
  );
}
NewOrderCustomer.propTypes = {
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
};
export default NewOrderCustomer;
