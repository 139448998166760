import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { REQUIRED_FIELD } from '../../lib/form';

function RadioButtonsGroup({ options, label, fieldName, control }) {
  const {
    field: { value, ...inputProps },
    formState: { errors },
  } = useController({
    name: fieldName,
    control,
    rules: { required: REQUIRED_FIELD },
  });
  const { onChange, onBlur, name } = inputProps;
  const typographyColor = errors && errors[fieldName] ? 'error' : undefined;
  return (
    <FormControl>
      <FormLabel id="controlled-radio-buttons-group">
        <Typography color={typographyColor}>{label}</Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        row
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={value || ''}
      >
        {options.map(({ value: optionValue, label: optionLabel }) => (
          <FormControlLabel
            key={optionValue}
            value={optionValue}
            control={<Radio color="secondary" />}
            label={optionLabel}
          />
        ))}
      </RadioGroup>
      <Typography color={typographyColor} variant="caption">
        {errors && errors[fieldName] && errors[fieldName].message}
      </Typography>
    </FormControl>
  );
}
RadioButtonsGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  label: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  fieldName: PropTypes.string.isRequired,
};
RadioButtonsGroup.defaultProps = {
  label: '',
};
export default RadioButtonsGroup;
