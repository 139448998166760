import { gql } from '@apollo/client';

const GET_PRESIGNED_GET_FILE_URL = gql`
  query getPresignedGetFileUrl($key: String!) {
    presignedGetFileUrl(key: $key) {
      key
      presignedUrl
    }
  }
`;

const GET_PRESIGNED_PUT_FILE_URL = gql`
  query getPresignedPutFileUrl($fileName: String!) {
    presignedPutFileUrl(fileName: $fileName) {
      key
      presignedUrl
    }
  }
`;

export { GET_PRESIGNED_GET_FILE_URL, GET_PRESIGNED_PUT_FILE_URL };
