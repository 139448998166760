import { offsetLimitPagination } from '@apollo/client/utilities';

function customOffsetLimitPagination(keyArgs = false) {
  return {
    keyArgs,
    read(existing, { args: { offset: offsetArg, limit: limitArg } }) {
      // A read function should always return undefined if existing is
      // undefined. Returning undefined signals that the field is
      // missing from the cache, which instructs Apollo Client to
      // fetch its value from your GraphQL server.
      if (!existing) return undefined;

      const offset = offsetArg || 0;
      const limit = limitArg || existing.total;

      const allPageNodes = existing.nodes
        ? existing.nodes.slice(offset, offset + limit)
        : [];
      const pageNodes = allPageNodes.filter((node) => !!node);
      const isLastPage = existing.total - offset < limit;

      if (existing.total) {
        if (!isLastPage && pageNodes.length < limit) {
          return undefined;
        } // the current page has to fetch more data from the server because is incomplete
        // if data is an empty array or, the array has an empty array inside, the page has to fetch more data from the server
        if (!allPageNodes.length || allPageNodes.length !== pageNodes.length) {
          return undefined;
        }
      }

      // to handle this case: [empty x 10], is an 'empty' array, but still has
      // length, this is possible bacause the user can skip some pages

      return {
        ...existing,
        // update new pageCount
        pageCount: Math.ceil(existing.total / limit),
        ...(existing.nodes && {
          nodes: pageNodes,
        }),
      };
    },
    merge(existing, incoming, options) {
      const {
        args,
        storeFieldName,
        cache: {
          data: {
            data: { ROOT_QUERY },
          },
        },
      } = options;
      const { total: incomingTotal } = incoming;
      const { total: existingTotal } =
        (ROOT_QUERY && ROOT_QUERY[storeFieldName]) || {};

      // update cache if data total changes
      if (existingTotal && incomingTotal !== existingTotal) {
        // do something
      } // this will trigger a fetch from the server
      // returning an undefined does not trigger a fetch
      const existingNodes = existing?.nodes || [];
      const { merge } = offsetLimitPagination();
      const merged = merge(existingNodes, incoming.nodes, { args });
      return {
        ...incoming,
        nodes: merged,
      };
    },
  };
}

export default customOffsetLimitPagination;
