import { Outlet, Routes, Route, Navigate } from 'react-router-dom';

import PackTable from './list';

function Packs() {
  return (
    <>
      <Outlet />
      <Routes>
        <Route path="*" element={<Navigate to="/catalogs/packs" />} />
        <Route path="/" element={<PackTable />} />
      </Routes>
    </>
  );
}

export default Packs;
