import Box from '@mui/material/Box';

import Error from '../components/Error';

function NotAuthorized() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        top: '80%',
        position: 'relative',
      }}
    >
      <Error message="No tienes los permisos necesarios para realizar esta acción" />
    </Box>
  );
}

export default NotAuthorized;
