import { useState } from 'react';

import { useMutation } from '@apollo/client';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';

import filterParser from '../../helpers/generateReportFilterParser';
import CustomDialog from './CustomDialog';

const REQUIRED_VALUE_MESSAGE = 'Campo requerido';
const INVALID_EMAIL_MESSAGE = 'Email inválido';

function ExportDataForm({
  reportProperties,
  inputVariables,
  filterOptions,
  openExportDataForm,
  setOpenExportDataForm,
  handleCloseExportDataForm,
}) {
  const [dialogTitle, setDialogTitle] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const watchEmail = useWatch({
    name: 'email',
    control,
  });
  const [generateReport, { loading, reset }] = useMutation(
    reportProperties.mutation,
    {
      onError: () => {
        setOpenExportDataForm(false);
        setDialogTitle('No se ha podido solicitar el reporte');
        setDialogMessage(
          <Typography>
            Ha ocurrido un error al intentar solicitar el informe. Inténtalo de
            nuevo más tarde.
          </Typography>,
        );
        setShowDialog(true);
        reset();
      },
      onCompleted: () => {
        setOpenExportDataForm(false);
        setDialogTitle(
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CheckCircleOutlinedIcon color="success" />
            <Typography variant="h5">
              Solicitud de reporte enviada con éxito
            </Typography>
          </Box>,
        );
        setDialogMessage(
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Alert severity="warning">
              La exportación del archivo puede tomar un tiempo
            </Alert>
            <Typography>
              Se enviará el reporte al correo: {watchEmail}
            </Typography>
          </Box>,
        );
        setShowDialog(true);
        reset();
      },
    },
  );

  const onSubmit = (_data) => {
    const filtersPrompt = Object.values(
      filterParser(inputVariables.filters, filterOptions),
    ).join('\r\n');
    const variables = {
      ...inputVariables,
      receiver: _data.email,
      filename: reportProperties.type,
      userPrompt: `Spreadsheet created with the following filters:\r\n ${filtersPrompt}`,
    };
    generateReport({ variables });
  };
  return (
    <>
      <Dialog open={openExportDataForm} onClose={handleCloseExportDataForm}>
        <DialogTitle>Exportar a Excel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingresa tu correo para recibir el archivo excel cuando la
            exportación se haya completado.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Correo electrónico"
            type="email"
            fullWidth
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('email', {
              required: REQUIRED_VALUE_MESSAGE,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: INVALID_EMAIL_MESSAGE,
              },
            })}
            variant="standard"
            error={Boolean(errors.email)}
            helperText={errors.email && errors.email.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExportDataForm} disabled={loading}>
            Cancelar
          </Button>
          {!loading ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              disabled={Boolean(errors.email)}
              onClick={handleSubmit(onSubmit)}
              endIcon={<SendOutlinedIcon />}
            >
              Enviar
            </Button>
          ) : (
            <CircularProgress color="secondary" size="2rem" />
          )}
        </DialogActions>
      </Dialog>
      {showDialog && (
        <CustomDialog
          dialogContent={dialogMessage}
          dialogTitle={{
            title: dialogTitle,
          }}
          dialogActions={
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => setShowDialog(false)}
            >
              Aceptar
            </Button>
          }
          openDialog={showDialog}
          handleCloseDialog={setShowDialog}
        />
      )}
    </>
  );
}

ExportDataForm.propTypes = {
  reportProperties: PropTypes.shape({
    type: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    mutation: PropTypes.object.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterOptions: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputVariables: PropTypes.object.isRequired,
  openExportDataForm: PropTypes.bool.isRequired,
  setOpenExportDataForm: PropTypes.func.isRequired,
  handleCloseExportDataForm: PropTypes.func.isRequired,
};
export default ExportDataForm;
