import { InMemoryCache } from '@apollo/client';

import customerParser from '../helpers/customerParser';
import dateParser from '../helpers/dateParser';
import measuresParser from '../helpers/measuresParser';
import offsetLimitPagination from './pagination';

const cache = new InMemoryCache({
  typePolicies: {
    Order: {
      fields: {
        createdAt: {
          read(createdAt) {
            return dateParser(createdAt);
          },
        },
        customer: {
          read(customer) {
            return customerParser(customer);
          },
        },
      },
    },
    Measures: {
      fields: {
        width: {
          read(width) {
            return measuresParser(width);
          },
        },
        height: {
          read(height) {
            return measuresParser(height);
          },
        },
        length: {
          read(length) {
            return measuresParser(length);
          },
        },
        weight: {
          read(weight) {
            return measuresParser(weight);
          },
        },
      },
    },
    ProductComponent: {
      fields: {
        componentType: {
          read(_, { readField }) {
            const maxComponentQuantity = readField('maxComponentQuantity');
            const quantity = readField('quantity');

            if (!quantity && !maxComponentQuantity) return '-';

            return maxComponentQuantity ? 'Dinámico' : 'Fijo';
          },
        },
      },
    },
    Product: {
      fields: {
        value: {
          read(_, { readField }) {
            return readField('id');
          },
        },
        label: {
          read(_, { readField }) {
            const name = readField('name');
            const sku = readField('sku');
            return `sku: ${sku} - ${name}`;
          },
        },
        volume: {
          read(_, { readField }) {
            const measures = readField('measures');

            if (!measures) return '-';

            const {
              length: lengthFromMeasures,
              height: heightFromMeasures,
              width: widthFromMeasures,
            } = measures;

            const length =
              !lengthFromMeasures?.unit || !lengthFromMeasures?.value
                ? '-'
                : `${lengthFromMeasures?.value} ${lengthFromMeasures?.unit} `;
            const height =
              !heightFromMeasures?.unit || !heightFromMeasures?.value
                ? '-'
                : `${heightFromMeasures?.value} ${heightFromMeasures?.unit}`;

            const width =
              !widthFromMeasures?.unit || !widthFromMeasures?.value
                ? '-'
                : `${widthFromMeasures?.value} ${widthFromMeasures?.unit}`;

            if (length === '-' && height === '-' && width === '-') {
              return '-';
            }

            return `${length} x ${height} x ${width}`;
          },
        },
        packType: {
          read(_, { readField }) {
            const components = readField('components');
            const hasMaxComponentQuantity =
              components.filter(
                (component) => component.maxComponentQuantity > 0,
              ).length > 0;

            if (hasMaxComponentQuantity) return 'Dinámico';

            return 'Fijo';
          },
        },
        weight: {
          read(_, { readField }) {
            const measures = readField('measures');

            if (!measures) return '-';
            const {
              weight: { unit: weightUnit, value: weightValue },
            } = measures;
            if (!weightValue || !weightUnit) return '-';
            return `${weightValue} ${weightUnit}`;
          },
        },
        componentsQuantity: {
          read(_, { readField }) {
            const components = readField('components');
            return components.length;
          },
        },
      },
    },
    ProductAtWarehouse: {
      fields: {
        lastUpdate: {
          read() {
            return dateParser(new Date());
          },
        },
      },
    },
    Replenishment: {
      fields: {
        createdAt: {
          read(createdAt) {
            return dateParser(createdAt);
          },
        },
      },
    },
    ReplenishmentItem: {
      fields: {
        expirationDate: {
          read(expirationDate) {
            return dateParser(expirationDate);
          },
        },
      },
    },
    Seller: {
      keyFields: ['id', 'name'],
    },
    Query: {
      fields: {
        getOrders: offsetLimitPagination(['filters', 'sort']),
        getProductsAtWarehouses: offsetLimitPagination(['filters', 'sort']),
        getProducts: offsetLimitPagination(['filters', 'sort']),
        replenishments: offsetLimitPagination(['filters', 'sort']),
      },
    },
  },
});

export default cache;
