import { useState, useMemo, useCallback } from 'react';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

function ArrayFormTextField({
  textFieldKey,
  register,
  width,
  error,
  helperText,
  inputType,
  select,
  defaultValue,
  label,
  placeholder,
  required,
}) {
  const { onChange, onBlur, name, ref } = register();

  const [currentWidth, setCurrentWidth] = useState();
  // TODO: review this logic and refactor it
  const inputRef = useCallback(
    (htmlInput) => {
      const value = htmlInput?.value;
      const refWidth = width || `${(String(value).length + 4) * 1.4}ch`;
      setCurrentWidth(refWidth);
      ref(htmlInput);
    },
    [ref],
  );
  const textFieldProps = useMemo(
    () => ({
      sx: { width: currentWidth },
      key: textFieldKey,
      size: 'small',
      type: inputType,
      error,
      onChange,
      onBlur,
      name,
      inputRef,
      helperText,
      select: select.select,
      defaultValue,
      label,
      placeholder,
      required,
    }),
    [inputRef],
  );

  if (!select.select) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TextField {...textFieldProps} />;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...textFieldProps}>
      {select.options.map(({ value: optionValue, label: optionLabel }) => (
        <MenuItem key={`${optionValue}`} value={optionValue}>
          {optionLabel}
        </MenuItem>
      ))}
    </TextField>
  );
}

ArrayFormTextField.propTypes = {
  textFieldKey: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  register: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputType: PropTypes.string.isRequired,
  select: PropTypes.shape({
    select: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }),
  control: PropTypes.shape({}),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};
ArrayFormTextField.defaultProps = {
  width: undefined,
  error: false,
  helperText: '',
  select: { select: false },
  control: {},
  defaultValue: undefined,
  label: undefined,
  placeholder: undefined,
  required: false,
};
export default ArrayFormTextField;
