import TableCellMui from '@mui/material/TableCell';
import TableRowMui from '@mui/material/TableRow';
import PropTypes from 'prop-types';

function SubComponentRow({ colSpan, children }) {
  return (
    <TableRowMui>
      <TableCellMui colSpan={colSpan}>{children}</TableCellMui>
    </TableRowMui>
  );
}

SubComponentRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default SubComponentRow;
