import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const SX_BOX = { padding: 2 };

function ReplenishmentSummary({ replenishment }) {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: grey[50],
        mb: 2,
      }}
    >
      <Box sx={SX_BOX}>
        <Typography variant="caption">Vendedor</Typography>
        <Typography>{replenishment.seller.name}</Typography>
      </Box>
      <Box sx={SX_BOX}>
        <Typography variant="caption">Tienda</Typography>
        <Typography>{replenishment.warehouse.name}</Typography>
      </Box>
      <Box sx={SX_BOX}>
        <Typography variant="caption">Fecha ingreso</Typography>
        <Typography>{replenishment.createdAt}</Typography>
      </Box>
      {replenishment.comment && (
        <Box sx={SX_BOX}>
          <Typography variant="caption">Comentario</Typography>
          <Typography>{replenishment.comment}</Typography>
        </Box>
      )}
    </Box>
  );
}
ReplenishmentSummary.propTypes = {
  replenishment: PropTypes.shape({
    seller: PropTypes.shape({ name: PropTypes.string }),
    warehouse: PropTypes.shape({ name: PropTypes.string }),
    createdAt: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
};
export default ReplenishmentSummary;
