import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';

const BOX_PROPS = {
  borderRadius: 15,
};

const PaperBox = styled(Paper)(() => BOX_PROPS);

export default PaperBox;
