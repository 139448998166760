import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import Table from '../../components/table/Table';

const PAGE_SIZE_OPTIONS = [10, 20, 30, 40];

function ListServerData({
  report,
  query,
  queryVariables,
  handleGlobalFilter,
  initGlobalFilter,
  dataKey,
  columns,
  renderSubComponent,
  editFormProperties,
  filters,
  disableSortBy,
  hasSearchBox,
}) {
  // Server Table with pagination and prefetch
  const [listData, setListData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [getData, { data, loading, error, client }] = useLazyQuery(query);

  const prefetch = ({ variables }) => {
    client.query({ query, variables });
  };
  useEffect(() => {
    // paginator pageSize by default is the first item of pageSizeOptions
    getData({
      variables: { offset: 0, limit: PAGE_SIZE_OPTIONS[0], ...queryVariables },
    });
  }, []);

  useEffect(() => {
    if (data) {
      const {
        [dataKey]: { nodes, pageCount: pageCountData },
      } = data;
      setListData(nodes);
      setPageCount(pageCountData);
    }
  }, [data]);

  return (
    <Table
      report={report}
      data={listData}
      columns={columns}
      renderSubComponent={renderSubComponent}
      editFormProperties={editFormProperties}
      pagination={{
        hasPaginator: true,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        controlledPageCount: pageCount,
        queryVariables,
        fetchMore: getData,
        prefetch,
      }}
      handleGlobalFilter={handleGlobalFilter}
      initGlobalFilter={initGlobalFilter}
      error={error}
      loading={loading}
      filters={filters}
      disableSortBy={disableSortBy}
      hasSearchBox={hasSearchBox}
    />
  );
}

ListServerData.propTypes = {
  report: PropTypes.shape({
    hasReport: PropTypes.bool,
    reportProperties: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      mutation: PropTypes.object,
      type: PropTypes.string,
      dialogFunctions: PropTypes.shape({
        open: PropTypes.bool,
        setOpen: PropTypes.func,
        handleClickOpen: PropTypes.func,
        handleClose: PropTypes.func,
      }),
      filterOptions: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        warehouses: PropTypes.arrayOf(PropTypes.object),
        // eslint-disable-next-line react/forbid-prop-types
        sellers: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  queryVariables: PropTypes.object,
  handleGlobalFilter: PropTypes.func,
  initGlobalFilter: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderSubComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  editFormProperties: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  filters: PropTypes.shape({
    components: PropTypes.node,
    helperTextSearchBox: PropTypes.string,
  }),
  disableSortBy: PropTypes.bool,
  hasSearchBox: PropTypes.bool,
};

ListServerData.defaultProps = {
  report: { hasReport: false },
  renderSubComponent: false,
  editFormProperties: false,
  queryVariables: {},
  handleGlobalFilter: () => {},
  initGlobalFilter: '',
  filters: {
    components: <div />,
  },
  disableSortBy: false,
  hasSearchBox: true,
};

export default ListServerData;
