import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import serverErrorImage from '../assets/images/server-error.svg';

function Error({ message, img }) {
  return (
    <>
      <Box
        component="img"
        sx={{
          height: 'auto',
          width: 200,
        }}
        alt="serverError"
        src={img}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography>Ups...</Typography>
        <Typography sx={{ textAlign: 'center' }} component="div">
          {message}
        </Typography>
      </Box>
    </>
  );
}

Error.propTypes = {
  message: PropTypes.string,
  img: PropTypes.string,
};

Error.defaultProps = {
  message: 'Ocurrió un error para cargar la información',
  img: serverErrorImage,
};
export default Error;
