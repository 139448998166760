import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

function ActionButton() {
  return (
    <Link
      to="/orders"
      style={{ textDecoration: 'none', color: 'currentColor' }}
    >
      <Button color="secondary" variant="contained">
        VER ÓRDENES
      </Button>
    </Link>
  );
}
export default ActionButton;
