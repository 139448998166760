import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import ChipNumber from '../../../components/chips/ChipNumber';
import MainBox from '../../../components/containers/MainBox';
import Error from '../../../components/Error';
import PageTitle from '../../../components/layout/PageTitle';
import SkeletonListData from '../../../components/skeletons/ListData';
import PAPER_BOX_PROPS from '../../../components/table/styles';
import Table from '../../../components/table/Table';
import { GET_REPLENISHMENT } from '../../../gql/replenishments';
import { CURRENCY_FORMATTER } from '../../../lib/currency';
import {
  REPLENISHMENT_STATUS_LABEL_MAPPING,
  REPLENISHMENT_STATUS_COLOR_MAPPING,
} from '../../../lib/replenishmentStatuses';
import HeaderListData from '../../commons/HeaderListData';
import ReplenishmentSummary from './ReplenishmentSummary';

const MIN_PAGE_SIZE = 1;

function Replenishment() {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_REPLENISHMENT, {
    variables: { id },
  });

  const replenishment = useMemo(() => {
    const {
      reference,
      seller,
      warehouse,
      items = [],
      comment,
      createdAt,
      status,
    } = data?.replenishment || {};
    return { reference, seller, warehouse, items, createdAt, comment, status };
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: <HeaderListData>Sku</HeaderListData>,
        accessor: 'sku',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <HeaderListData>Cantidad informada</HeaderListData>,
        accessor: 'quantity',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) =>
          (value === 0 || value) && <ChipNumber label={value} />,
      },
      {
        Header: <HeaderListData>Cantidad recepcionada</HeaderListData>,
        accessor: 'quantityDelta',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) =>
          (value === 0 || value) && <ChipNumber label={value} />,
      },
      {
        Header: <HeaderListData>Costo unitario</HeaderListData>,
        accessor: 'unitCost',
        // eslint-disable-next-line react/prop-types
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { costCurrency },
          },
        }) => (
          <Typography>
            {CURRENCY_FORMATTER[costCurrency]
              ? CURRENCY_FORMATTER[costCurrency].format(value)
              : ''}
          </Typography>
        ),
      },
      {
        Header: <HeaderListData>Número de lote</HeaderListData>,
        accessor: 'lotNumber',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <HeaderListData>Fecha vencimiento</HeaderListData>,
        accessor: 'expirationDate',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
    ],
    [],
  );
  if (error) {
    return (
      <MainBox>
        <Error />
      </MainBox>
    );
  }
  if (loading)
    return (
      <>
        <PageTitle>Abastecimiento</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <PageTitle>Abastecimiento {replenishment.reference}</PageTitle>
        <Chip
          label={
            <Typography sx={{ fontWeight: 500 }}>
              {REPLENISHMENT_STATUS_LABEL_MAPPING[replenishment.status]}
            </Typography>
          }
          color={REPLENISHMENT_STATUS_COLOR_MAPPING[replenishment.status]}
          variant="outlined"
        />
      </Box>
      <ReplenishmentSummary replenishment={replenishment} />
      <Table
        data={replenishment.items}
        columns={columns}
        pagination={{
          hasPagination: false,
          customPageSize: replenishment.items.length || MIN_PAGE_SIZE,
        }}
        hasStickyHeader={false}
        hasSearchBox={false}
        paperBoxProps={PAPER_BOX_PROPS}
        loading={loading}
        error={error}
      />
    </Box>
  );
}
export default Replenishment;
