import LaunchIcon from '@mui/icons-material/Launch';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  REPLENISHMENT_STATUS_LABEL_MAPPING,
  REPLENISHMENT_STATUS_COLOR_MAPPING,
} from '../../lib/replenishmentStatuses';
import HeaderListData from '../commons/HeaderListData';

const columns = () => [
  {
    Header: <HeaderListData>ID referencia</HeaderListData>,
    accessor: 'reference',
    disableSortBy: true,
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData>Estado</HeaderListData>,
    accessor: 'status',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => (
      <Tooltip title="El abastecimiento tiene esta secuencia de estados: creado y recibido.">
        <Chip
          label={
            <Typography sx={{ fontWeight: 500 }}>
              {REPLENISHMENT_STATUS_LABEL_MAPPING[value]}
            </Typography>
          }
          color={REPLENISHMENT_STATUS_COLOR_MAPPING[value]}
          variant="outlined"
        />
      </Tooltip>
    ),
  },
  {
    Header: <HeaderListData>Fecha creación</HeaderListData>,
    accessor: 'createdAt',
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => <Typography>{value}</Typography>,
  },
  {
    Header: '',
    accessor: 'id',
    disableSortBy: true,
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => (
      <Link
        href={`/replenishments/${value}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          Ver
          <LaunchIcon sx={{ width: '0.8em' }} />
        </Box>
      </Link>
    ),
  },
];
export default columns;
