import { useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';

import TabPanel from 'components/tabs/TabPanel';

import Packs from './packs';
import Products from './products';

function MainCatalog() {
  const { pathname } = useLocation();

  const [value, setValue] = useState(
    pathname.includes('/catalogs/packs') ? 1 : 0,
  );

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          aria-label="catalog-tabs"
          textColor="secondary"
          indicatorColor="secondary"
          onChange={handleChange}
          value={value}
        >
          <Tab label="Productos" component={Link} to="products" />
          <Tab label="Packs" component={Link} to="packs" />
        </Tabs>
      </Box>
      <Routes>
        <Route path="*" element={<Navigate to="/catalogs/products" />} />
        <Route
          path="products/*"
          element={
            <TabPanel value={value} index={0}>
              <Products />
            </TabPanel>
          }
        />
        <Route
          path="packs/*"
          element={
            <TabPanel value={value} index={1}>
              <Packs />
            </TabPanel>
          }
        />
      </Routes>
    </Box>
  );
}

export default MainCatalog;
