import { gql } from '@apollo/client';

const GET_PRODUCTS_AT_WAREHOUSES = gql`
  query getProductsAtWarehouses(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: ProductAtWarehouseFilters!
  ) {
    getProductsAtWarehouses(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        quantityAvailable
        seller {
          id
          name
        }
        warehouse {
          id
          name
        }
        product {
          id
          sku
          name
        }
      }
      total
      pageCount
    }
  }
`;

const GET_PRODUCTS = gql`
  query getProducts(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: ProductFilters!
  ) {
    getProducts(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        name
        sku
        value @client
        label @client
        componentsQuantity @client
        packType @client
        storageType {
          value
          label
        }
        seller {
          id
          name
        }
        barcode
        measures {
          width {
            value
            unit
          }
          height {
            value
            unit
          }
          length {
            value
            unit
          }
          weight {
            value
            unit
          }
        }
        components {
          productId
          maxComponentQuantity
          quantity
        }
      }
      total
      pageCount
    }
  }
`;

const GET_PRODUCT = gql`
  query getProduct($sellerId: ID!, $id: ID!) {
    product(sellerId: $sellerId, id: $id) {
      id
      sku
      name
      seller {
        id
        name
      }
      storageType {
        value
        label
      }
      measures {
        width {
          value
          unit
        }
        length {
          value
          unit
        }
        height {
          value
          unit
        }
        weight {
          value
          unit
        }
      }
      components {
        productId
        maxComponentQuantity
        sellerId
        quantity
        componentType @client
        product {
          id
          sku
          name
          storageType {
            value
            label
          }
          measures {
            width {
              value
              unit
            }
            length {
              value
              unit
            }
            height {
              value
              unit
            }
            weight {
              value
              unit
            }
          }
          volume @client
          weight @client
        }
      }
    }
  }
`;

const GET_PRODUCT_AT_WAREHOUSE_BY_IDS = gql`
  query getProductAtWarehouseByIds(
    $sellerId: String!
    $warehouseId: String!
    $productId: String!
  ) {
    getProductAtWarehouseByIds(
      sellerId: $sellerId
      warehouseId: $warehouseId
      productId: $productId
    ) {
      id
      product {
        id
      }
      quantityAvailable
      lastUpdate @client
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      name
      seller {
        id
        name
      }
      measures {
        width {
          value
          unit
        }
        height {
          value
          unit
        }
        length {
          value
          unit
        }
        weight {
          value
          unit
        }
      }
      storageType {
        value
        label
      }
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      measures {
        height {
          value
          unit
        }
        length {
          unit
          value
        }
        weight {
          unit
          value
        }
        width {
          unit
          value
        }
      }
      name
      seller {
        name
        id
      }
      sku
      storageType {
        label
        value
      }
    }
  }
`;

export {
  GET_PRODUCTS_AT_WAREHOUSES,
  GET_PRODUCT_AT_WAREHOUSE_BY_IDS,
  GET_PRODUCTS,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  GET_PRODUCT,
};
