import { gql } from '@apollo/client';

const GET_CHECKOUT_RATES = gql`
  query getCheckoutRates {
    checkoutRates(input: $input)
      @rest(
        type: "CheckoutResponse"
        path: "sellers_config/{args.input.sellerConfigId}/platforms/andromeda/rates"
        method: "POST"
      ) {
      warehouseId @type(name: "ID")
      rates @type(name: "Rate") {
        serviceType
        serviceName
        shippingMethod
        carrierCode
        currency
        price
      }
    }
  }
`;
export default GET_CHECKOUT_RATES;
