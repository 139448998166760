import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

import FileInput from '../../../../../components/input-files/FileInput';
import FileUpload from '../../../../../components/input-files/FileUpload';
import FILE_TYPES from '../../../../../lib/fileTypes';

function NewOrderAttachments({ control, register, setValue }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
  });

  return (
    <Box>
      <FileInput
        fileTypes={[FILE_TYPES.PDF, FILE_TYPES.PNG, FILE_TYPES.JPEG]}
        fileLabel="PDF o imágenes"
        fileParser={(currentFiles) => {
          const newFiles = currentFiles.reduce(
            (acc, curr) => ({ ...acc, [curr.name]: curr }),
            {},
          );
          const attachments = Object.keys(newFiles).map((name) => ({
            name,
            title: '',
            key: '',
            isUploaded: fields
              .map(({ name: currentName }) => currentName)
              .includes(name),
            file: newFiles[name],
          }));
          append(attachments.filter((attachment) => !attachment.isUploaded));
        }}
        multipleFiles
        showSubmittedFiles={false}
      />
      {Boolean(fields.length) && (
        <Typography gutterBottom>Archivos adjuntos:</Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {fields.map((field, index) => (
          <Box
            key={field.name}
            sx={{
              display: 'flex',
              borderRadius: 2,
              border: `1px solid ${grey[400]}`,
              justifyContent: 'space-between',
              padding: 2,
            }}
          >
            <FileUpload
              file={field.file}
              fileIndex={index}
              handleClose={() => {
                remove(index);
              }}
              control={control}
              register={register}
              setValue={setValue}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
NewOrderAttachments.propTypes = {
  control: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
export default NewOrderAttachments;
