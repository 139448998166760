import { useEffect, useState, useMemo, useCallback } from 'react';

import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

function FileInput({
  fileTypes,
  fileLabel,
  fileParser,
  multipleFiles,
  error,
  errorMessage,
  showSubmittedFiles,
}) {
  const [submittedFileName, setSubmittedFileName] = useState();
  const dropzoneFileTypes = useMemo(
    () => fileTypes.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {}),
    [fileTypes],
  );
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: dropzoneFileTypes,
    multiple: multipleFiles,
  });
  const {
    onBlur,
    onClick,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    onFocus,
    onKeyDown,
  } = useMemo(() => getRootProps(), []);
  const {
    accept,
    multiple,
    onChange,
    onClick: onClickInput,
    style,
    type,
  } = useMemo(() => getInputProps(), []);

  const focusedStyle = useCallback((theme, hasFocusedStyle) => {
    if (!hasFocusedStyle) {
      return {};
    }
    return { borderColor: theme.palette.info.main };
  }, []);
  const acceptStyle = useCallback((theme, hasAcceptStyle) => {
    if (!hasAcceptStyle) {
      return {};
    }
    return { borderColor: theme.palette.success.main };
  }, []);
  const rejectStyle = useCallback((theme, hasRejectStyle) => {
    if (!hasRejectStyle) {
      return {};
    }
    return { borderColor: theme.palette.error.main };
  }, []);

  const readFile = (files) => {
    fileParser(files);
  };
  useEffect(() => {
    if (acceptedFiles.length) {
      setSubmittedFileName(acceptedFiles.map(({ name }) => name).join(', '));
      readFile(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <Box>
      <Paper
        variant="outlined"
        onBlur={onBlur}
        onClick={onClick}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        sx={(theme) => ({
          cursor: 'grab',
          border: `0.125rem dashed ${theme.palette.dropzoneBorder.main}`,
          '&:hover': {
            backgroundColor: theme.palette.dropzoneBackgroundColor.main,
          },
          ...focusedStyle(theme, isFocused),
          ...acceptStyle(theme, isDragAccept),
          ...rejectStyle(theme, isDragReject || error),
        })}
      >
        <Box>
          <input
            accept={accept}
            multiple={multiple}
            onChange={onChange}
            onClick={onClickInput}
            style={style}
            type={type}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 4,
            }}
          >
            <UploadFileRoundedIcon fontSize="large" />
            <Typography>
              Haz click para subir un archivo o suéltalo aquí
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column' }}>
        {error && errorMessage && (
          <Typography variant="caption" color="error">
            {errorMessage}
          </Typography>
        )}
        <Typography variant="caption">
          Archivos permitidos: {fileLabel}
        </Typography>
        {submittedFileName && showSubmittedFiles && (
          <Typography>Archivo cargado: {submittedFileName}</Typography>
        )}
      </Box>
    </Box>
  );
}
FileInput.propTypes = {
  fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileLabel: PropTypes.string.isRequired,
  fileParser: PropTypes.func.isRequired,
  multipleFiles: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  showSubmittedFiles: PropTypes.bool,
};

FileInput.defaultProps = {
  multipleFiles: false,
  error: false,
  showSubmittedFiles: true,
  errorMessage: '',
};

export default FileInput;
