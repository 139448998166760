import { Outlet, Routes, Route, Navigate } from 'react-router-dom';

import ProductList from './list';

function Products() {
  return (
    <>
      <Outlet />
      <Routes>
        <Route path="*" element={<Navigate to="/catalogs/products" />} />
        <Route path="/" element={<ProductList />} />
      </Routes>
    </>
  );
}

export default Products;
