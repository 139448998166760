import { useQuery } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';

import loginRedirect from '../auth/utils';
import ProtectedView from '../components/layout/ProtectedView';
import IS_LOGGED_IN from '../gql/auth';

function ProtectedRoute({ children }) {
  const { data, error } = useQuery(IS_LOGGED_IN);
  const { isLoggedIn } = data || {};
  if (isLoggedIn) return <ProtectedView>{children}</ProtectedView>;
  if (error) loginRedirect();
  return <LinearProgress color="secondary" />;
}
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
