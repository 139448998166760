import { gql } from '@apollo/client';

const GET_INVENTORY = gql`
  query getProductsAtWarehouses(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: ProductAtWarehouseFilters!
  ) {
    getProductsAtWarehouses(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        quantityAvailable
        seller {
          id
          name
        }
        warehouse {
          id
          name
        }
        product {
          id
          sku
          name
        }
      }
      total
      pageCount
    }
  }
`;
export default GET_INVENTORY;
