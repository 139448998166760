import Box from '@mui/material/Box';

function Logo() {
  return (
    <Box
      component="img"
      sx={{
        height: 40,
      }}
      alt="logo-store"
      src="https://s3.amazonaws.com/storecentral.apps.andromeda/sellers/store-central/logos/main.png"
    />
  );
}

export default Logo;
