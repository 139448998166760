import { useReactiveVar } from '@apollo/client';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';

import { globalLoaderVar } from '../../apollo/reactiveVariables';
import ActionButton from './ActionButton';
import Logo from './Logo';
import UserProfile from './UserProfile';

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  borderRadius: 'inherit',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: theme.drawer.width,
    width: `calc(100% - ${theme.drawer.width}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function AppBar({ open, handleDrawerOpen }) {
  const globalLoader = useReactiveVar(globalLoaderVar);
  return (
    <StyledAppBar color="inherit" position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Logo />
          <Box>
            <ActionButton />
            <UserProfile />
          </Box>
        </Box>
      </Toolbar>
      {globalLoader && <LinearProgress color="secondary" />}
    </StyledAppBar>
  );
}

AppBar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
};
export default AppBar;
