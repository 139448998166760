import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function MainBox({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        top: '80%',
        position: 'relative',
      }}
    >
      {children}
    </Box>
  );
}
MainBox.propTypes = {
  children: PropTypes.node.isRequired,
};
export default MainBox;
