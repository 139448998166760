import { Fragment } from 'react';

import TableBodyMui from '@mui/material/TableBody';
import TableCellMui from '@mui/material/TableCell';
import TableRowMui from '@mui/material/TableRow';
import PropTypes from 'prop-types';

import SubComponentRow from './SubComponentRow';

function TableBody({
  rows,
  prepareRow,
  getTableBodyProps,
  hasHover,
  visibleColumns,
  renderSubComponent,
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableBodyMui {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Fragment key={`table-row-${row.id}`}>
            <TableRowMui
              hover={hasHover}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => (
                <TableCellMui
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...cell.getCellProps()}
                  padding={cell.column.padding ? cell.column.padding : 'normal'}
                >
                  {cell.render('Cell')}
                </TableCellMui>
              ))}
            </TableRowMui>
            {row.isExpanded && renderSubComponent && (
              <SubComponentRow colSpan={visibleColumns.length}>
                {renderSubComponent(row)}
              </SubComponentRow>
            )}
          </Fragment>
        );
      })}
    </TableBodyMui>
  );
}
TableBody.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  prepareRow: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  hasHover: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderSubComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
    .isRequired,
};

export default TableBody;
