import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import CustomDialog from 'views/commons/CustomDialog';

const TUTORIAL_TITLE = '¿Cómo cargar múltiples órdenes?';

function TutorialContent() {
  return (
    <Box>
      <Typography>
        Descarga el excel de ejemplo y complétalo con la información de las
        órdenes que vas crear. El archivo tiene las siguientes columnas:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="orderNumber (campo obligatorio)"
            secondary="Número de la orden: Es importante para que puedas darle un seguimiento a las órdenes. Si no lo agregas, el sistema no creará la orden."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="businessType (campo obligatorio)"
            secondary="Tipo de orden: Debes elegir entre B2B y B2C. Si eliges B2B, no podrás seleccionar un tipo de envío. Las opciones: SAME_DAY, EXPRESS, STANDARD y PICK_UP son exclusivamente para B2C."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="currency (campo obligatorio)"
            secondary="Moneda: Debes elegir entre CLP (Chile) y PEN (Perú)."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="shippingMethod (campo obligatorio en B2C)"
            secondary="Tipo de envío: Debes elegir entre: SAME_DAY, EXPRESS. STANDARD, PICK_UP. Estos tipos de envío son exclusivos de las órdenes B2C. Si creas una orden B2B, deja vacío este campo."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="shippingValue (campo obligatorio)"
            secondary="Valor del envío: Los precios tienen el impuesto incluido. IVA (Chile) / IGV (Perú)."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="lineItemQuantity (campo obligatorio)"
            secondary="Cantidad del item: Si creas órdenes y no tienes stock suficiente del item, el sistema las ingresará, pero no podrán ser despachadas."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="lineItemPrice (campo obligatorio)"
            secondary="Valor unitario del SKU: Es el precio unitario del producto. Si el valor del producto es 5000 y compras 3, lineItemQuantity será 3 y lineItemPrice 5000. Recuerda que los valores tienen el impuesto incluido."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="lineItemSku (campo obligatorio)"
            secondary="SKU: Debe estar registrado previamente en nuestro sistema. Recuerda completar este campo como cadena de texto y no como número para que no existan problemas a la hora de leer los datos."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="shippingAddressLine1 (campo obligatorio)"
            secondary="Dirección de envío: Debes completar la dirección con el máximo detalle: calle, número, comuna, dirección y región. Recuerda que si creas una orden con un tipo de envío fuera de la cobertura de Store Central, se rechazará la orden."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="shippingAddressLine2 (campo opcional)"
            secondary="Complemento: Este campo entrega más detalle de la dirección. Otros ejemplos: depto 32, casa A, etc."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="customerFirstName (campo obligatorio)"
            secondary="Nombre cliente: Tener la información del cliente lo más completa posible nos ayuda a entregar un mejor servicio."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="customerLastName (campo obligatorio)"
            secondary="Apellido cliente"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="customerEmail (campo obligatorio)"
            secondary="Correo cliente"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="customerPhone (campo obligatorio)"
            secondary="Teléfono cliente"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="comment (campo opcional)"
            secondary="Comentario: Puedes completarlo si quieres agregar información adicional a la orden, o una referencia."
          />
        </ListItem>
      </List>
    </Box>
  );
}
function TutorialDialog({ showDialog, setShowDialog }) {
  return (
    <CustomDialog
      dialogContent={<TutorialContent />}
      dialogTitle={{
        title: TUTORIAL_TITLE,
      }}
      dialogActions={
        <Button
          variant="outlined"
          size="small"
          onClick={() => setShowDialog(false)}
        >
          OK
        </Button>
      }
      openDialog={showDialog}
      handleCloseDialog={setShowDialog}
    />
  );
}

TutorialDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
export default TutorialDialog;
