import dateParser from './dateParser';

const IDS_OBJECT_MAPPING = {
  sellerId: 'sellers',
  warehouseId: 'warehouses',
};
const FILTER_KEYS_MAPPING = {
  sinceDate: 'Since date',
  toDate: 'To date',
  sellerId: 'Sellers',
  warehouseId: 'Warehouses',
  shippingMethod: 'Shipping method',
  onlyInvalidAddress: 'Pending address verification',
  globalFilter: 'Search filter',
};

function generateReportFilterParser(filter, idOptions) {
  const parsedFilter = {};
  Object.keys(filter).forEach((key) => {
    let parsedValue = '';

    switch (key) {
      case 'sinceDate':
      case 'toDate':
        parsedValue = dateParser(filter[key]);
        break;
      case 'sellerId':
      case 'warehouseId':
        if (Array.isArray(filter[key])) {
          parsedValue = filter[key]
            .map(
              (id) =>
                idOptions[IDS_OBJECT_MAPPING[key]].find(
                  (obj) => obj.value === id,
                ).label,
            )
            .toString();
        } else if (typeof filter[key] === 'string') {
          parsedValue = idOptions[IDS_OBJECT_MAPPING[key]].find(
            (el) => el.value === filter[key],
          ).label;
        }
        break;
      default:
        parsedValue = filter[key].toString();
        break;
    }

    if (key in FILTER_KEYS_MAPPING) {
      parsedFilter[key] = `${FILTER_KEYS_MAPPING[key]}: ${parsedValue}`;
    }
    return key;
  });
  return parsedFilter;
}
export default generateReportFilterParser;
