import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function CellText({ value }) {
  return <Typography>{value}</Typography>;
}

CellText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CellText;
